import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Seccion from 'components/Modificaciones/Seccion'

function Ejes() {

	// render
	return (
		<Container fluid id="seccion3" className="vh-100">
			<Row>
				<Col className="mt-2">
					<h1>Modificación en contenidos de la sección Ejes</h1>
					<hr />
					<Seccion
						nombreSeccion="ejes"
					/>
				</Col>
			</Row>
		</Container>
	)
}

export default Ejes