import axios from 'axios';
import { toast } from 'react-toastify';
import { isLoggedIn } from 'utils/general';
import { getData } from 'utils/localstorage';

const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

api.interceptors.request.use(
	config => {
		if (isLoggedIn()) {
			const hash = getData('hash');

			config.headers.common.Authorization = `Basic ${hash}`;
			if (config.method === 'get' || config.method === 'delete') {
				const separator = config.url.includes('?') ? '&' : '?';
				config.url += separator + 'cuentaId=' + getData('cuentaId');
			} else if (config.method === 'post' || config.method === 'put') {
				if (config.data instanceof FormData) {
					config.data.append('cuentaId', getData('cuentaId'))
				} else {
					config.data = {
						...config.data,
						cuentaId: getData('cuentaId')
					}
				}
			}
		}

		return config;
	},
	error => {
		// Do something with request error
		return Promise.reject(error);
	}
);

api.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
	if (typeof response.data.datos === 'undefined') {
		toast.error('Server error:' + response.data)
		throw new Error(response.data)
	}

	if (response.data.error) {
		toast.error(response.data.msg)
		return Promise.reject(response.data.msg)
	}
	
    return response.data;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
	//toast.error(error.response?.data?.msg ? error.response.data.msg : error.message);
	toast.error(error.response && error.response.data && error.response.data.msg ? error.response.data.msg : error.message);
    return Promise.reject(error);
  });

export default api;