import React from 'react'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import { es } from 'date-fns/locale'

import './Calendario.css'

const locales = {
	es,
}

const messages = {
	week: 'Semana',
	work_week: 'Semana de trabajo',
	day: 'Día',
	month: 'Mes',
	previous: 'Atrás',
	next: 'Adelante',
	today: 'Hoy',
	agenda: 'El Diario',

	showMore: (total) => `+${total} más`,
}

const localizer = dateFnsLocalizer({
	format,
	parse,
	startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 0 }),
	getDay,
	locales,
})

const Calendario = ({ proyectos }) => {
	const hoy = new Date()
	hoy.setHours(0, 0, 0, 0)

	// Crea un array de eventos a partir de los hitos de los proyectos
	const eventos = proyectos?.flatMap(proyecto =>
		proyecto.hitos.map(hito => {
			let conDemoras = false
			if (!hito.completado && new Date(hito.fecha) < hoy) {
				conDemoras = true
			}

			return {
				title: hito.nombre,
				start: new Date(hito.fecha),
				end: new Date(hito.fecha),
				conDemoras
			}
		})
	)

	const calendarRef = React.useRef(null)
	React.useEffect(() => {
		if (calendarRef.current) {
			calendarRef.current.reposition()
		}
	}, [])

	const getColorForProject = (conDemoras) => {
		if (conDemoras) {
			return '#f85252'
		} else {
			return '#88d432'
		}
		
	}

	return (
		<div style={{ minHeight: '600px', height: 'calc(100vh - 300px' }}>
			<div className="mb-2">Mostrando <strong>{eventos.length}</strong> hitos de <strong>{proyectos.length}</strong> proyectos</div>
			<Calendar
				localizer={localizer}
				events={eventos}
				startAccessor="start"
				culture="es"
				endAccessor="end"
				views={['month']}
				popup
				messages={messages}
				step={3600}
				eventPropGetter={(event) => ({
					style: {
						backgroundColor: getColorForProject(event.conDemoras),
					},
				})}
			/>
		</div>
	)
}

export default Calendario