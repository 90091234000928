import Offcanvas from 'react-bootstrap/Offcanvas'
import Button from 'react-bootstrap/Button'

import './Panel.css'

function PanelHeader(props) {
	const { onClick, iconClass, btnText, text } = props
	return (
		<Offcanvas.Header closeButton className="panel-header">
			<Offcanvas.Title className="d-flex align-items-center" >
				<Button className="rounded-pill me-2" onClick={onClick}><i className={`fa ${iconClass}`}></i> {btnText}</Button> {text}
			</Offcanvas.Title>
		</Offcanvas.Header>
	)
}

export default PanelHeader
