import React, { useContext, useEffect, useState, useRef } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { OfertasConfigContext } from 'contexts/OfertasConfigContext'
import useOfertasFiltradas from 'hooks/useOfertasFiltradas'
import ListaOfertas from './ListaOfertas'
import VerOferta from './VerOferta'
import { FiltrosFormWrapper } from 'components/FiltrosFormWrapper'
import { MultiSelectCustom } from 'components/MultiSelectCustom'
import { debounce } from 'utils/debounce'
import { SelectDateRange } from 'components/SelectDateRange'
import { getInicioAnho } from 'utils/date'
import { toast } from 'react-toastify'
import AsyncSelect from 'react-select/async'

import api from 'api/api'

const OfertasHistorial = () => {
	const { data: config } = useContext(OfertasConfigContext)
	const [filtrosIniciales, setFiltrosIniciales] = useState({
		finalizado: true,
		busqueda: '',
		fecha: {
			inicio: getInicioAnho(),
			fin: new Date()
		},
	})
	const { loading, ofertas, numResultados, cambiarPagina, filtros, cambiarFiltro, pagina, fetchDeACuantos, setFetchDeACuantos } = useOfertasFiltradas({ ...filtrosIniciales })
	const areasOptions = config.areas.map((e) => ({
		value: e.id,
		label: e.nombre,
	}))
	const [areas, setAreas] = useState(areasOptions)
	const [inicializado, setInicializado] = useState(false)
	const [busqueda, setBusqueda] = useState('')
	const busquedaAnteriorRef = useRef(busqueda)
	const [accionActual, setAccionActual] = useState({
		ofertaId: null,
		accion: ''
	})

	const loadOptions = async (inputValue, callback) => {
		const { datos: respuesta } = await api.post('a/buscar-usuarios', { nombreCompleto: inputValue })
		callback(respuesta)
	}

	const onChangeBusquedaUsuario = (selected) => {
		if (selected) {
			cambiarFiltro(selected.value, 'usuarioId')
		} else {
			cambiarFiltro('', 'usuarioId')
		}
	}

	// Define las acciones específicas para ofertas actuales
	const accionesHistorial = {
		handleVer: (ofertaId) => {
			setAccionActual({
				ofertaId,
				accion: 'ver'
			})
		},
		// handleDesmarcarFinalizar: (ofertaId) => {
		// 	setAccionActual({
		// 		ofertaId,
		// 		accion: 'desmarcarFinalizado'
		// 	})
		// }
	}

	const cerrarAccion = () => {
		setAccionActual({
			ofertaId: null,
			accion: ''
		})
	}

	useEffect(() => {
		if (inicializado) {
			const areasIds = areas.map(e => e.value)
			cambiarFiltro(areasIds, 'areaId')
		} else {
			setInicializado(true)
		}
	}, [areas])

	const debouncedSetFiltroBusqueda = useRef(
		debounce((str) => {
			if (str !== busquedaAnteriorRef.current) {
				cambiarFiltro(str, 'busqueda')
			}
			busquedaAnteriorRef.current = str
		}, 400)
	).current

	useEffect(() => {
		debouncedSetFiltroBusqueda(busqueda)
	}, [busqueda])

	const onChangeFechas = (fechaInicio, fechaFin) => {
		cambiarFiltro(
			{
				inicio: fechaInicio,
				fin: fechaFin
			}
		, 'fecha')
	}

	const desmarcarFinalizar = async (id) => {
		const { msg } = await api.post('a/ofertas/' + id + '/finalizar', { finalizar: false })
		cambiarFiltro(filtros.busqueda, 'busqueda') // para que haga un refresh
		cerrarAccion()
		toast.success(msg)
	}

	return (
		<Container fluid id="ofertas-pendientes" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col xs={12} sm={6} lg={4} className="mt-2">
					<h1>Historial de ofertas</h1>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col>
					<FiltrosFormWrapper>
						<Row className="vw-100">
							<Row>
								<Col>
									<SelectDateRange
										desde={filtros.fecha.inicio}
										hasta={filtros.fecha.fin}
										onChange={onChangeFechas}
									/>
								</Col>
							</Row>
							<Row>
								<Col md={4} lg={3}>
									<Form.Group className="mb-3" controlId="filtro-areasIds">
										<Form.Label>Áreas</Form.Label>
										<MultiSelectCustom
											options={areasOptions}
											value={areas}
											onChange={(e) => setAreas(e)}
										/>
									</Form.Group>
								</Col>
								<Col md={4} lg={3}>
									<Form.Group className="mb-3" controlId="filtro-busqueda">
										<Form.Label>Búsqueda</Form.Label>
										<Form.Control
											type="text"
											value={busqueda}
											onChange={(e) => setBusqueda(e.target.value)}
										/>
									</Form.Group>
								</Col>
								<Col md={4} lg={3}>
									<Form.Group className="mb-3" controlId="filtro-busqueda">
										<Form.Label>Usuario</Form.Label>
										<AsyncSelect
											cacheOptions
											loadOptions={loadOptions}
											defaultOptions
											onChange={onChangeBusquedaUsuario}
											isClearable={true}
											placeholder={'Buscar...'}
										/>
									</Form.Group>
								</Col>
							</Row>
						</Row>
					</FiltrosFormWrapper>
				</Col>
			</Row>
			<ListaOfertas
				loading={loading}
				ofertas={ofertas}
				numResultados={numResultados}
				pagina={pagina}
				fetchDeACuantos={fetchDeACuantos}
				cambiarPagina={cambiarPagina}
				acciones={accionesHistorial}
			/>
			{
				accionActual.accion === 'ver' ? (
					<VerOferta
						oferta={ofertas.find(p => p.id === accionActual.ofertaId)}
						onClose={cerrarAccion}
						mostrarContactos
					/>
				) : null
			}
			{/* {
				accionActual.accion === 'desmarcarFinalizado' ? (
					<DesmarcarFinalizarOferta
						oferta={ofertas.find(d => d.id === accionActual.ofertaId)}
						onClose={cerrarAccion}
						desmarcarFinalizar={desmarcarFinalizar}
					/>
				) : null
			} */}
		</Container>
	)
}

export default OfertasHistorial