import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import { PanelFooter, PanelHeader } from 'components/Panel'

import './sectores.css'
import { Offcanvas } from 'react-bootstrap'
import Thumb from 'components/Thumb/Thumb'

function VerSector(props) {
	const { sector, setQueMostrar } = props

	const handleClose = () => {
		setQueMostrar('')
	}

	// render
	return (
		<Offcanvas show={true} onHide={handleClose} placement="end" scroll="true">
			<PanelHeader onClick={handleClose} iconClass="fa-arrow-left" btnText="Atrás" text="Ver sector" />
			<Offcanvas.Body>
				<h1>Datos del sector</h1>
				<Row>
					<Col sm={12} md={6}>
						<Table>
							<thead></thead>
							<tbody>
								<tr>
									<th>Id</th>
									<td>{sector.id}</td>
								</tr>
								<tr>
									<th>Nombre</th>
									<td>{sector.nombre}</td>
								</tr>
								<tr>
									<th>Orden</th>
									<td>{sector.orden || '-'}</td>
								</tr>
                                <tr>
									<th>Imagen</th>
									<td>{(sector.imagenUrl) ? <Thumb size={200} alt="Imagen" url={process.env.REACT_APP_API_URL + sector.imagenUrl}/> : <Thumb size={200} url={null}/>}</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
			</Offcanvas.Body>
			<PanelFooter>
				<Button variant="primary" onClick={handleClose}>
					Ok
				</Button>
			</PanelFooter>
		</Offcanvas>
	)
}

export default VerSector