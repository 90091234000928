import React from 'react'

const Avatar = ({ name, url, size = 40 }) => {
	const initials = name.charAt(0).toUpperCase()

	const stringToColor = (str) => {
		let hash = 0;
		for (let i = 0; i < str.length; i++) {
			hash = Math.imul(31, hash) + str.charCodeAt(i) | 0
		}
		const r = (hash & 0xff0000) >> 16
		const g = (hash & 0x00ff00) >> 8
		const b = hash & 0x0000ff
		return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`
	}

	if (url) {
		return (
			<img
				src={url}
				alt={name}
				style={{
					width: size,
					height: size,
					borderRadius: '50%',
					objectFit: 'cover',
					margin: 'auto',
				}}
			/>
		)
	} else {
		return (
			<span
				style={{
					width: size,
					height: size,
					borderRadius: '50%',
					backgroundColor: stringToColor(name),
					display: 'inline-flex',
					justifyContent: 'center',
					alignItems: 'center',
					fontSize: size / 2,
					color: '#fff',
				}}
			>
				{initials}
			</span>
		)
	}
}

export default Avatar