import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { toast } from 'react-toastify'

import { PanelFooter, PanelHeader } from 'components/Panel'
import './home.css'

function Home(props) {
	const [showPanel, setShowPanel] = useState(false)

	useEffect(() => {
		toast.success('Inicio!')
	}, [])

	const onClosePanel = () => {
		setShowPanel(false)
	}

	// render
	return (
		<Container fluid id="home" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col xs={12} sm={6} lg={4} className="mt-2">
					<h1>Inicio</h1>
					<hr />
					Contenido del Dashboard
				</Col>
			</Row>
		</Container>
	)
}

export default Home