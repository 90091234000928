import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { FormLabel, Offcanvas } from 'react-bootstrap'
import { PanelFooter, PanelHeader } from 'components/Panel'
import FileUpload from 'components/FileUpload/FileUpload'
import { PasswordInput } from 'components/PasswordInput'
import { useForm } from 'hooks/useForm'

function NuevoUsuario(props) {
	const { mostrar, tiposDisponibles, guardar, cancelar } = props

	const opciones = tiposDisponibles.map(elem => ({ label: elem.nombre, value: elem.id }))
	const datosIniciales = {
		usuario: '',
		nombre: '',
		apellido: '',
		email: '',
		contrasena: '',
		confirmarContrasena: '',
		tipo: opciones[0]?.value
	}

	const [loading, setLoading] = useState(false)
	const [datos, setDatos] = useState(datosIniciales)

	const handleClose = () => {
		cancelar()
	}

	useEffect(() => {
		if (!mostrar) {
			reset()
		}
	}, [mostrar])

	const handleProcesar = async (datos) => {
		const datosUsuario = {
			usuario: datos.usuario,
			nombre: datos.nombre,
			apellido: datos.apellido,
			email: datos.email,
			contrasena: datos.contrasena,
			tipo: datos.tipo
		}

		setLoading(true)
		await guardar(datosUsuario)
		setLoading(false)
	}

	const validateFields = (data) => {
		const newErrors = {}
		let firstInvalidId = null

		// Cada campo debe tener un id único que coincida con su nombre
		Object.keys(data).forEach((key) => {
			const error = validateField(key, data[key])
			if (error && !firstInvalidId) {
				// Guarda el id del primer campo inválido
				firstInvalidId = key
			}
			if (error) {
				newErrors[key] = error
			}
		})

		if (!data.contrasena) {
			newErrors['contrasena'] = 'La contraseña es obligatoria'
			firstInvalidId = 'contrasena'
		} else {
			if (data.contrasena.length < 8) {
				newErrors['contrasena'] = 'La contraseña debe tener al menos 8 caracteres'
				firstInvalidId = 'contrasena'
			} else if (data.contrasena !== data.confirmarContrasena) {
				newErrors['confirmarContrasena'] = 'Las contraseñas no coinciden'
				firstInvalidId = 'confirmarContrasena'
			}
		}

		return { errors: newErrors, firstInvalidId }
	}

	const validateField = (name, value) => {
		switch (name) {
			case 'usuario':
			case 'nombre':
				if (value.trim() === '') {
					return 'El campo es obligatorio'
				}
				break
			/*
			case 'correo':
				if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
					return 'Ingresa un correo válido'
				}
				break
			*/

			default:
				return null // no hay error
		}
	}

	const { data, handleChange, handleSubmit, errors, updateInitialData } = useForm(
		datosIniciales, // Estado inicial
		validateFields, // Función de validación
		handleProcesar // Función a ejecutar cuando el formulario es válido
	)

	const reset = () => {
		setDatos(datosIniciales)
		updateInitialData(datosIniciales)
	}

	// render
	return (
		<Offcanvas show={mostrar} onHide={handleClose} placement="end" scroll="true">
			<PanelHeader onClick={handleClose} iconClass="fa-arrow-left" btnText="Atrás" text="Nuevo usuario" />
			<Offcanvas.Body id="nuevo-usuario">
				<h1>Campos del usuario</h1>
				<Row>
					<Col sm={12} md={6}>
						<Form onSubmit={handleProcesar} className="ms-4">
							<Form.Group className="mb-3" controlId="usuario">
								<FormLabel>Usuario*</FormLabel>
								<Form.Control
									value={data.usuario}
									onChange={(e) => handleChange(e.target.value, 'usuario')}
									isInvalid={!!errors.usuario}
								/>
								<Form.Control.Feedback type="invalid">{errors.usuario}</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className="mb-3" controlId="nombre">
								<FormLabel>Nombre*</FormLabel>
								<Form.Control
									value={data.nombre}
									onChange={(e) => handleChange(e.target.value, 'nombre')}
									isInvalid={!!errors.nombre}
								/>
								<Form.Control.Feedback type="invalid">{errors.nombre}</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className="mb-3" controlId="apellido">
								<FormLabel>Apellido</FormLabel>
								<Form.Control
									value={data.apellido}
									onChange={(e) => handleChange(e.target.value, 'apellido')}
									isInvalid={!!errors.apellido}
								/>
								<Form.Control.Feedback type="invalid">{errors.apellido}</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className="mb-3" controlId="email">
								<FormLabel>Email</FormLabel>
								<Form.Control
									type="email"
									value={data.email}
									onChange={(e) => handleChange(e.target.value, 'email')}
									isInvalid={!!errors.email}
								/>
								<Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
							</Form.Group>
							<PasswordInput
								className="mb-3"
								label="Contraseña"
								controlId="contrasena"
								placeholder="Contraseña"
								value={data.contrasena}
								onChange={(e) => handleChange(e.target.value, 'contrasena')}
								isInvalid={!!errors.contrasena}
								invalidFeedback={errors.contrasena}
								showLock
							/>
							<PasswordInput
								className="mb-3"
								label="Verificar contraseña"
								controlId="confirmarContrasena"
								placeholder="Confirmar contraseña"
								value={data.confirmarContrasena}
								onChange={(e) => handleChange(e.target.value, 'confirmarContrasena')}
								isInvalid={!!errors.confirmarContrasena}
								invalidFeedback={errors.confirmarContrasena}
								showLock
							/>
							<Form.Select id="tipo" value={data.tipo} onChange={(e) => handleChange(e.target.value, 'tipo')}>
								{opciones.map((opcion) => (
									<option key={opcion.value} value={opcion.value}>
										{opcion.label}
									</option>
								))}
							</Form.Select>
						</Form>
					</Col>
				</Row>
			</Offcanvas.Body>
			<PanelFooter>
				<Button variant="outline-tertiary" onClick={handleClose} className="me-2" disabled={loading}>
					Cancelar
				</Button>
				<Button
					variant="primary"
					onClick={handleSubmit}
					disabled={loading}
				>
					{loading ? <i className="fa fa-spinner fa-spin"></i> : null} Guardar
				</Button>
			</PanelFooter>
		</Offcanvas>
	)
}

export default NuevoUsuario