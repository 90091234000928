import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { FormLabel, Offcanvas } from 'react-bootstrap'
import { PanelFooter, PanelHeader } from 'components/Panel'
import { TagRol } from 'components/Tags'

function VerUsuario(props) {
	const { usuario, tiposDisponibles, mostrar, cancelar } = props

	const opciones = tiposDisponibles.map(elem => ({ label: elem.nombre, value: elem.id }))

	const datosIniciales = {
		usuario: usuario?.usuario || '',
		nombre: usuario?.nombre || '',
		apellido: usuario?.apellido || '',
		email: usuario?.email || '',
		tipo: opciones.find(tipo => usuario?.tipo === tipo.label)?.value,
        oferente: usuario?.oferente == 1 ? true : false,
        demandante: usuario?.demandante == 1 ? true : false,
        proyectista: usuario?.proyectista == 1 ? true : false,
        mecenas: usuario?.mecenas == 1 ? true : false,
	}
	const [datos, setDatos] = useState(datosIniciales)

	useEffect(() => {
		setDatos({ ...datosIniciales })
	}, [usuario])

	const handleClose = () => {
		cancelar()
	}

	// render
	return (
		<Offcanvas show={mostrar} onHide={handleClose} placement="end" scroll="true">
			<PanelHeader onClick={handleClose} iconClass="fa-arrow-left" btnText="Atrás" text="Ver usuario" />
			<Offcanvas.Body id="ver-usuario">
				<h1>Datos del usuario</h1>
                <Row>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'start', padding: '5px 20px' }}>
                            {datos.oferente ? <><TagRol rol="Oferente" />&nbsp;</> : null}
                            {datos.demandante ? <><TagRol rol="Demandante" />&nbsp;</> : null}
                            {datos.proyectista ? <><TagRol rol="Proyectista" />&nbsp;</> : null}
                            {datos.mecenas ? <><TagRol rol="Mecenas" />&nbsp;</> : null}
                        </div>                        
                    </Col>
                </Row>
				<Row>
					<Col sm={12} md={6}>
						<Form onSubmit={(e) => e.preventDefault()} className="ms-4" disabled>
							<Form.Group className="mb-3" controlId="usuario">
								<FormLabel>Usuario</FormLabel>
								<Form.Control
									disabled
									defaultValue={datos.usuario}
								/>
							</Form.Group>
							<Form.Group className="mb-3" controlId="nombre">
								<FormLabel>Nombre</FormLabel>
								<Form.Control
									disabled
									defaultValue={datos.nombre}
								/>
							</Form.Group>
							<Form.Group className="mb-3" controlId="apellido">
								<FormLabel>Apellido</FormLabel>
								<Form.Control
									disabled
									defaultValue={datos.apellido}
								/>
							</Form.Group>
							<Form.Group className="mb-3" controlId="email">
								<FormLabel>Email</FormLabel>
								<Form.Control
									type="email"
									defaultValue={datos.email}
									disabled
								/>
							</Form.Group>
							<Form.Select id="tipo" value={datos.tipo} disabled>
								{opciones.map((opcion) => (
									<option key={opcion.value} value={opcion.value}>
										{opcion.label}
									</option>
								))}
							</Form.Select>
						</Form>
					</Col>
				</Row>
			</Offcanvas.Body>			
		</Offcanvas>
	)
}

export default VerUsuario