import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import { PanelFooter, PanelHeader } from 'components/Panel'

import './ofertas.css'
import { Offcanvas } from 'react-bootstrap'
import { Avatar } from 'components/Avatar'
import { Rating } from 'react-simple-star-rating'

function VerOferta(props) {
	const { oferta, onClose, mostrarContactos = false } = props

	const handleClose = () => {
		onClose()
	}

	const areasStr = oferta.areas.map(area => area.nombre).join(', ')

	// render
	return (
		<Offcanvas show={true} onHide={handleClose} placement="end" scroll="true">
			<PanelHeader onClick={handleClose} iconClass="fa-arrow-left" btnText="Atrás" text="Ver oferta" />
			<Offcanvas.Body>
				
				<Row>
					<Col sm={6} md={6}>
						<h1>Datos de la oferta</h1>
						<Table>
							<thead></thead>
							<tbody>
								<tr>
									<th>Id</th>
									<td>{oferta.id}</td>
								</tr>
								<tr>
									<th>Oferente</th>
									<td>
										{oferta.usuario ? <Col sm={6} md={6}>						
											<Row>
												<Col style={{width: 110}}> 
													<Avatar name={oferta.usuario.nombre} url={oferta.usuario.avatar ? process.env.REACT_APP_API_URL + oferta.usuario.avatar : null} size={100} />									
												</Col>
												<Col md={true}>														
														<Rating
															initialValue={oferta.usuario.calificacion}
															allowTitleTag={false}
															readonly={true}
															allowFraction={true}
															size={30}
															className="mt-1"
														/>
														<div>
															{oferta.usuario.nombre}
														</div>
														<div>
															<a href={`mailto:${oferta.usuario.email}`}>{oferta.usuario.email}</a>
														</div>
														<div>
															<a href={`tel:${oferta.usuario.telefono}`}>{oferta.usuario.telefono}</a>
														</div>
												</Col>
											</Row>
										</Col> : null}	
									</td>
								</tr>
								<tr>
									<th>Nombre</th>
									<td>{oferta.nombre}</td>
								</tr>
								<tr>
									<th>Creado</th>
									<td>{oferta.fechaCreacion}</td>
								</tr>
								<tr>
									<th>Áreas</th>
									<td>{areasStr}</td>
								</tr>
								<tr>
									<th style={{width: 170}}># Visualizaciones</th>
									<td>{oferta.visualizaciones.length}</td>
								</tr>
								<tr>
									<th>Experiencia</th>
									<td>{oferta.experiencia}</td>
								</tr>
								<tr>
									<th>Link al portafolio</th>
									<td>{oferta.portafolioUrl ? <a href={oferta.portafolioUrl} target="_blank">{oferta.portafolioUrl}</a> : '-'}</td>
								</tr>
							</tbody>
						</Table>
					</Col>							
				</Row>
			</Offcanvas.Body>
			<PanelFooter>
				<Button variant="primary" onClick={handleClose}>
					Ok
				</Button>
			</PanelFooter>
		</Offcanvas>
	)
}

export default VerOferta