import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import { PanelFooter, PanelHeader } from 'components/Panel'

import './consultorias.css'
import { Offcanvas } from 'react-bootstrap'

function VerConsultoria(props) {
	const { consultoria, setQueMostrar } = props

	const handleClose = () => {
		setQueMostrar('')
	}

	const partes = consultoria.documento?.url.split('/');

	// Toma el último elemento (nombre del archivo)
	const nombreArchivo = partes ? partes[partes.length - 1] : null;

	// Elimina la parte de la fecha y hora al principio
	const nombreLimpio = nombreArchivo?.replace(/^\d{4}-\d{2}-\d{2}_\d{6}_/, '');

	// render
	return (
		<Offcanvas show={true} onHide={handleClose} placement="end" scroll="true">
			<PanelHeader onClick={handleClose} iconClass="fa-arrow-left" btnText="Atrás" text="Ver consultoría" />
			<Offcanvas.Body>
				<h1>Datos de la consultoría</h1>
				<Row>
					<Col sm={12} md={6}>
						<Table>
							<thead></thead>
							<tbody>
								<tr>
									<th>Id</th>
									<td>{consultoria.id}</td>
								</tr>
								<tr>
									<th>Fecha recepción</th>
									<td>{consultoria.fechaRecibido.substring(0, 10)}</td>
								</tr>
								<tr>
									<th>Estado</th>
									<td>{consultoria.estado}</td>
								</tr>
								<tr>
									<th>Nombre</th>
									<td>{consultoria.nombreCompleto}</td>
								</tr>
								<tr>
									<th>Correo</th>
									<td>{consultoria.correo || '-'}</td>
								</tr>
								<tr>
									<th>Título</th>
									<td>{consultoria.titulo || '-'}</td>
								</tr>
								<tr>
									<th>Descripción</th>
									<td>{consultoria.descripcion || '-'}</td>
								</tr>
								<tr>
									<th>Url</th>
									<td>{consultoria.url ? <a href={consultoria.url} target="_blank" rel="noopener noreferrer">{consultoria.url}</a> : '-'}</td>
								</tr>
								<tr>
									<th>Documento adjuntado</th>
									<td>
										{
											consultoria.documento ? (
												<span><i className="fa fa-file-pdf" /> <a href={process.env.REACT_APP_API_URL + consultoria.documento.url}>{nombreLimpio}</a></span>
											) : '-'
										}
									</td>
								</tr>
							</tbody>
						</Table>
					</Col>

				</Row>
			</Offcanvas.Body>
			<PanelFooter>
				<Button variant="primary" onClick={handleClose}>
					Ok
				</Button>
			</PanelFooter>
		</Offcanvas>
	)
}

export default VerConsultoria