import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Seccion from 'components/Modificaciones/Seccion'

import api from 'api/api'

function ConectivoComoFunciona() {
	
	// render
	return (
		<Container fluid id="conectivo-como-funciona" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col className="mt-2">
					<h1>Modificación de contenido de la sección CómoFunciona</h1>
					<hr />
					<Seccion
						nombreSeccion="conectivo-como-funciona"
					/>
				</Col>
			</Row>
		</Container>
	)
}

export default ConectivoComoFunciona