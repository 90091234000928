import React, { useState } from 'react'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

export default function PasswordInput(props) {
	const { placeholder, controlId, label, value, onChange, showLock = false, isInvalid = false, disabled = false, invalidFeedback = '',...otherProps } = props
	const [type, setType] = useState('password')
	const [icon, setIcon] = useState('eye')

	const handleToggle = () => {
		if (type === 'password') {
			setType('text')
			setIcon('eye-slash')
		} else {
			setType('password')
			setIcon('eye')
		}
	};

	return (
		<Form.Group controlId={controlId} {...otherProps}>
			<Form.Label className="mb-1">{label}</Form.Label>
			<InputGroup>
				{showLock && (
					<InputGroup.Text>
						<i className="fa fa-lock" />
					</InputGroup.Text>
				)}
				<Form.Control
					type={type}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					isInvalid={isInvalid}
					disabled={disabled}
				/>
				<Button onClick={handleToggle} style={{ cursor: 'pointer' }}>
					<i className={`fa fa-${icon}`} />
				</Button>
				{
					invalidFeedback ? (
						<Form.Control.Feedback type="invalid">
							{invalidFeedback}
						</Form.Control.Feedback>
					) : null
				}
			</InputGroup>
		</Form.Group>
	)
}

