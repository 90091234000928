import React, { useContext, useEffect, useState, useRef } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { DemandasConfigContext } from 'contexts/DemandasConfigContext'
import useDemandasFiltradas from 'hooks/useDemandasFiltradas'
import ListaDemandas from './ListaDemandas'
import VerDemanda from './VerDemanda'
import AprobarDemanda from './AprobarDemanda'
import ComentarDemanda from './ComentarDemanda'
import { FiltrosFormWrapper } from 'components/FiltrosFormWrapper'
import { MultiSelectCustom } from 'components/MultiSelectCustom'
import { debounce } from 'utils/debounce'
import { toast } from 'react-toastify'
import AsyncSelect from 'react-select/async'

import api from 'api/api'

const DemandasPendientes = () => {
	const { data: config } = useContext(DemandasConfigContext)
	const [filtrosIniciales, setFiltrosIniciales] = useState({ estado: 'pendiente', busqueda: '' })
	const { loading, demandas, numResultados, cambiarPagina, filtros, cambiarFiltro, pagina, fetchDeACuantos, setFetchDeACuantos } = useDemandasFiltradas({ ...filtrosIniciales })
	const areasOptions = config.areas.map((e) => ({
		value: e.id,
		label: e.nombre,
	}))
	const [areas, setAreas] = useState(areasOptions)
	const [inicializado, setInicializado] = useState(false)
	const [busqueda, setBusqueda] = useState('')
	const busquedaAnteriorRef = useRef(busqueda)
	const [accionActual, setAccionActual] = useState({
		demandaId: null,
		accion: ''
	})

	const loadOptions = async (inputValue, callback) => {
		const { datos: respuesta } = await api.post('a/buscar-usuarios', { nombreCompleto: inputValue })
		callback(respuesta)
	}

	const onChangeBusquedaUsuario = (selected) => {
		if (selected) {
			cambiarFiltro(selected.value, 'usuarioId')
		} else {
			cambiarFiltro('', 'usuarioId')
		}
	}

	// Las acciones específicas para demandas pendientes
	const accionesPendientes = {
		handleVer: (demandaId) => {
			setAccionActual({
				demandaId,
				accion: 'ver'
			})
		},
		handleAprobar: (demandaId) => {
			setAccionActual({
				demandaId,
				accion: 'aprobar'
			})
		},
		handleComentar: (demandaId) => {
			setAccionActual({
				demandaId,
				accion: 'comentar'
			})
		}
	}

	const cerrarAccion = () => {
		setAccionActual({
			demandaId: null,
			accion: ''
		})
	}

	useEffect(() => {
		if (inicializado) {
			const areasIds = areas.map(e => e.value)
			cambiarFiltro(areasIds, 'areaId')
		} else {
			setInicializado(true)
		}
	}, [areas])

	const debouncedSetFiltroBusqueda = useRef(
		debounce((str) => {
			if (str !== busquedaAnteriorRef.current) {
				cambiarFiltro(str, 'busqueda')
			}
			busquedaAnteriorRef.current = str
		}, 400)
	).current

	useEffect(() => {
		debouncedSetFiltroBusqueda(busqueda)
	}, [busqueda])

	const aprobar = async (id) => {
		const { msg } = await api.post('a/demandas/' + id + '/aprobar')
		cambiarFiltro(filtros.busqueda, 'busqueda') // para que haga un refresh
		cerrarAccion()
		toast.success(msg)
	}

	const comentar = async (id, comentario) => {
		const { msg } = await api.post('a/demandas/' + id + '/comentar', { comentario })
		cambiarFiltro(filtros.busqueda, 'busqueda') // para que haga un refresh
		cerrarAccion()
		toast.success(msg)
	}

	return (
		<Container fluid id="demandas-pendientes" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col xs={12} sm={6} lg={4} className="mt-2">
					<h1>Demandas pendientes</h1>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col>
					<FiltrosFormWrapper>
						<Row className="vw-100">
							<Row>
								<Col md={4} lg={3}>
									<Form.Group className="mb-3" controlId="filtro-areasIds">
										<Form.Label>Áreas</Form.Label>
										<MultiSelectCustom
											options={areasOptions}
											value={areas}
											onChange={(e) => setAreas(e)}
										/>
									</Form.Group>
								</Col>
								<Col md={4} lg={3}>
									<Form.Group className="mb-3" controlId="filtro-busqueda">
										<Form.Label>Búsqueda</Form.Label>
										<Form.Control
											type="text"
											value={busqueda}
											onChange={(e) => setBusqueda(e.target.value)}
										/>
									</Form.Group>
								</Col>
								<Col md={4} lg={3}>
									<Form.Group className="mb-3" controlId="filtro-busqueda">
										<Form.Label>Usuario</Form.Label>
										<AsyncSelect
											cacheOptions
											loadOptions={loadOptions}
											defaultOptions
											onChange={onChangeBusquedaUsuario}
											isClearable={true}
											placeholder={'Buscar...'}
										/>
									</Form.Group>
								</Col>
							</Row>
						</Row>
					</FiltrosFormWrapper>
				</Col>
			</Row>
			<ListaDemandas
				loading={loading}
				demandas={demandas}
				numResultados={numResultados}
				pagina={pagina}
				fetchDeACuantos={fetchDeACuantos}
				cambiarPagina={cambiarPagina}
				acciones={accionesPendientes}
			/>
			{
				accionActual.accion === 'ver' ? (
					<VerDemanda
						demanda={demandas.find(p => p.id === accionActual.demandaId)}
						onClose={cerrarAccion}
					/>
				) : null
			}
			{
				accionActual.accion === 'aprobar' ? (
					<AprobarDemanda
						demanda={demandas.find(p => p.id === accionActual.demandaId)}
						onClose={cerrarAccion}
						aprobar={aprobar}
					/>
				) : null
			}
			{
				accionActual.accion === 'comentar' ? (
					<ComentarDemanda
						demanda={demandas.find(p => p.id === accionActual.demandaId)}
						onClose={cerrarAccion}
						comentar={comentar}
					/>
				) : null
			}
		</Container>
	)
}

export default DemandasPendientes