import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import { PanelFooter, PanelHeader } from 'components/Panel'

import './proyectos.css'
import { Offcanvas } from 'react-bootstrap'
import ApoyoItem from './ApoyoItem'
import ApoyoPopup from './ApoyoPopup'

import moment from 'moment'

function VerProyecto(props) {
	const { proyecto, onClose } = props

	const [selectedApoyo, setSelectedApoyo] = useState(null)

	const handleSelectApoyo = (apoyo) => {
		setSelectedApoyo(apoyo)
	}

	const cerrarApoyo = () => {
		setSelectedApoyo(null)
	}

	const handleClose = () => {
		onClose()
	}

	const areasStr = proyecto.areas.map(proyecto => proyecto.nombre).join(', ')

	// render
	return (
		<Offcanvas show={true} onHide={handleClose} placement="end" scroll="true">
			<PanelHeader onClick={handleClose} iconClass="fa-arrow-left" btnText="Atrás" text="Ver proyecto" />
			<Offcanvas.Body>
				<h1>Datos del proyecto</h1>
				<Row>
					<Col sm={12} md={6}>
						<Table>
							<thead></thead>
							<tbody>
								<tr>
									<th>Id</th>
									<td>{proyecto.id}</td>
								</tr>
								<tr>
									<th>Usuario</th>
									<td>{proyecto.usuario.nombre}</td>
								</tr>
								<tr>
									<th>Postulante</th>
									<td>{proyecto.postulante}</td>
								</tr>
								<tr>
									<th>Nombre</th>
									<td>{proyecto.nombre}</td>
								</tr>
								<tr>
									<th>Presentado</th>
									<td>{proyecto.fechaPresentado}</td>
								</tr>
								<tr>
									<th>Áreas</th>
									<td>{areasStr}</td>
								</tr>
							</tbody>
						</Table>
						<h2>Introducción</h2>
						{proyecto.introduccion}
						<h2 className="mt-3">Descripción</h2>
						{proyecto.descripcion}
						<h2 className="mt-3">Fundamentación</h2>
						{proyecto.fundamentacion}
						<h2>Objetivos</h2>
						{proyecto.objetivos}
						<h2>Metodología</h2>
						{proyecto.metodologia}
						<h2>Alcance</h2>
						{proyecto.alcance}
						<h2>Sostenibilidad</h2>
						{proyecto.sostenibilidad}
						<h2>Riesgo</h2>
						{proyecto.riesgo}
						<h2>Impacto</h2>
						{proyecto.impacto}
						<h2>Cronograma</h2>
						{proyecto.cronograma}
						<h2>Presupuesto</h2>
						{proyecto.presupuesto}
						<h2>Enlace proveído</h2>
						{proyecto.url ? <a href={proyecto.url} rel="noopener noreferrer" target="_blank">{proyecto.url}</a> : '-'}
					</Col>
					<Col sm={12} md={6} className="text-center">
						<h3>Imagen</h3>
						<div className="logo-img">
							<img src={proyecto.multimedia ? process.env.REACT_APP_API_URL + proyecto.multimedia.url : 'logo192.png'} alt={proyecto.nombre} />
						</div>
						<h3 className="sin-subrayado text-start mt-4">Cronograma</h3>
						{proyecto.hitos.length > 0 ? (
							<Table hover >
								<thead>
									<tr>
										<th>Hitos</th>
										<th style={{ width: 200 }}>Fecha fijada</th>
										<th style={{ width: 200 }}>Fecha completado</th>
									</tr>
								</thead>
								<tbody>
									{proyecto.hitos.map((ht, i) => {
										return (
											<tr key={i}>
												<td>
													<i className="fa fa-monument"></i>&nbsp;&nbsp;
													{ht.nombre}
												</td>
												<td>
													<i className="fa fa-calendar-alt" style={{ color: "#ccc" }}></i>&nbsp;&nbsp;{moment(ht.fecha).format("DD/MM/YYYY")}
												</td>
												<td>
													{ht.completado ? <i className="fa fa-check-circle" style={{ color: "#198754" }}></i> : <i className="fa fa-clock" style={{ color: "#ffc107" }}></i>}
													&nbsp;&nbsp;{ht.fechaCompletado ? moment(ht.fechaCompletado).format("DD/MM/YYYY") : "-"}
												</td>
											</tr>
										)}
									)}
								</tbody>
							</Table>
						) : (
							<Alert variant="info">No hay hitos cargados</Alert>
						)}
						<h3 className="sin-subrayado text-start mt-4">Ofrecen apoyo</h3>
						{proyecto.apoyos.length ? (
							proyecto.apoyos.map(apoyo => {

								return (
									<ApoyoItem key={apoyo.id} apoyo={apoyo} onSelect={handleSelectApoyo} />
								)
							})
						) : (
							<Alert>Todavía no hay ofrecimientos de apoyo para esta oferta.</Alert>
						)}
					</Col>
				</Row>
			</Offcanvas.Body>
			<PanelFooter>
				<Button variant="primary" onClick={handleClose}>
					Ok
				</Button>
			</PanelFooter>
			{selectedApoyo && <ApoyoPopup apoyo={selectedApoyo} onClose={cerrarApoyo} />}
		</Offcanvas>
	)
}

export default VerProyecto