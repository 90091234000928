import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import { toast } from 'react-toastify'
import api from 'api/api'

import './usuarios.css'

import { DEFAULT_FETCH_CUANTOS } from 'utils/constants'
import { debounce } from 'utils/debounce'
import ListaUsuarios from './ListaUsuarios'
import NuevoUsuario from './NuevoUsuario'
import EditarUsuario from './EditarUsuario'
import EliminarUsuario from './EliminarUsuario'
import VerUsuario from './VerUsuario'

function Administradores(props) {
	const [fetchDeACuantos, setFetchDeACuantos] = useState(DEFAULT_FETCH_CUANTOS)
	const [numResultados, setNumResultados] = useState(0)
	const [paginaAct, setPaginaAct] = useState(1)
	const [loading, setLoading] = useState(false)

	const [busqueda, setBusqueda] = useState('')
	const [filtroBusqueda, setFiltroBusqueda] = useState('')
	const [usuarios, setUsuarios] = useState([])
	const [tipos, setTipos] = useState([])
	const [accionActual, setAccionActual] = useState({
		usuario: null,
		accion: ''
	})

	const sinImagenUrl = 'https://via.placeholder.com/80x80?text=Sin+imagen'

	const opcionesCuantosMostrar = [10, 25, 50]

	const refreshConfig = async () => {
		try {
			const { datos: respuesta } = await api.get('a/cuentas-tipos')
			setTipos(respuesta)
		} catch (error) {
			toast.error('Error ')
		}
	}

	const refreshUsuarios = async () => {
		try {
			setLoading(true)
			const { datos } = await api.post('a/ver-administradores', {
				cuantos: fetchDeACuantos,
				desde: paginaAct === 1 ? 0 : (paginaAct - 1) * fetchDeACuantos,
				filtros: {
					usuario: [filtroBusqueda],
					nombre: [filtroBusqueda]
				}
			})

			setUsuarios(datos.usuarios)
			setNumResultados(datos.numResultados)
		} catch (error) {
			toast.error('Error de conexión')
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const handleBusqueda = (value) => {
		if (value) {
			setFiltroBusqueda(value)
		} else {
			setFiltroBusqueda('')
		}
	}

	const debouceFiltroBusqueda = React.useCallback(
		debounce(handleBusqueda, 400),
		[]
	)

	useEffect(() => {
		debouceFiltroBusqueda(busqueda)
	}, [busqueda])

	useEffect(() => {
		if (paginaAct === 1) {
			refreshUsuarios()
		} else {
			setPaginaAct(1)
		}
	}, [filtroBusqueda, fetchDeACuantos])

	useEffect(() => {
		if (!loading) {
			refreshUsuarios()
		}
	}, [paginaAct])

	const subirArchivo = async (archivo) => {
		// Primero sube el archivo, luego crea el socio
		const serverData = new FormData();
		serverData.append('foto', archivo)
		let multimediaId = null

		try {
			const { datos, msg } = await api.post('a/multimedia', serverData)
			multimediaId = datos.id
		} catch (error) {
			toast.error('Error ')
		}

		return multimediaId
	}

	const nuevoUsuario = async (data) => {
		try {
			const { msg } = await api.post('a/administradores', data)
			refreshConfig()
			refreshUsuarios()
			cerrarAccion()
			toast.success(msg)
		} catch (error) {
			console.error(error)
			toast.error('Error de conexión')
		}
	}

	const editarUsuario = async (data) => {
		if (Object.keys(data).length) {
			const enviar = {
				...data,
				usuarioId: accionActual.usuario.id
			}

			try {
				const { msg } = await api.put('a/usuarios/' + accionActual.usuario.id, enviar)
				refreshConfig()
				refreshUsuarios()
				cerrarAccion()
				toast.success(msg)
			} catch (error) {
				console.error(error)
				toast.error('Error de conexión')
			}
		}
	}

	const eliminarUsuario = async (id) => {
		const { msg } = await api.delete('a/usuarios/' + id)
		refreshConfig()
		refreshUsuarios()
		cerrarAccion()
		toast.success(msg)
	}

	useEffect(() => {
		if (!loading) {
			refreshConfig()
			refreshUsuarios()
		}
	}, [])

	// Las acciones específicas para la lista de usuarios
	const accionesActuales = {
		handleVer: (usuario) => {
			setAccionActual({
				usuario,
				accion: 'ver'
			})
		},
		handleEditar: (usuario) => {
			setAccionActual({
				usuario,
				accion: 'editar'
			})
		},
		handleEliminar: (usuario) => {
			setAccionActual({
				usuario,
				accion: 'eliminar'
			})
		},
	}

	const cerrarAccion = () => {
		setAccionActual({
			usuario: null,
			accion: ''
		})
	}


	// render
	return (
		<Container fluid id="usuarios" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col xs={12} className="mt-2">
					<h1>Administradores</h1>
					<hr />
				</Col>
			</Row>
			<Row className="d-flex no-gutters">
				<Col className="mt-2">
					<Form>
						<Row id="searchbar" className="justify-content-between align-items-center">
							<Col sm={6} md={3} style={{ display: 'block' }}>
								<small>
									<span className="d-none d-sm-inline-block">Mostrar de a</span>
									<Form.Group style={{ display: 'inline-block' }}>
										<Form.Select size="sm" onChange={(e) => setFetchDeACuantos(e.target.value)} className="mx-1">
											{opcionesCuantosMostrar.map((o) => {
												return (
													<option value={o} key={`mostrar-${o}`}>
														{o}
													</option>
												)
											})}
										</Form.Select>
									</Form.Group>
									<span className="ms-2">usuarios</span>
								</small>
							</Col>
							<Col md={5} lg={4}>
								<Form.Group controlId="txtBusqueda">
									<InputGroup>
										<Form.Control
											autoFocus
											value={busqueda}
											onChange={(e) => setBusqueda(e.target.value)}
											type="text"
											placeholder="Buscar"
											aria-label="Buscar"
										/>
										<InputGroup.Text><Form.Label className="mb-0"><i className="fa fa-search"></i></Form.Label></InputGroup.Text>
									</InputGroup>
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col>
					<Button
						variant="primary"
						onClick={() => setAccionActual({
							usuarioId: null,
							accion: 'nuevo'
						})}
						className="mt-2"
					>
						<i className="fa fa-plus"></i> Nuevo
					</Button>
				</Col>
			</Row>
			{/* Lista usuarios */}
			<ListaUsuarios
				loading={loading}
				usuarios={usuarios}
				numResultados={numResultados}
				pagina={paginaAct}
				fetchDeACuantos={fetchDeACuantos}
				cambiarPagina={setPaginaAct}
				acciones={accionesActuales}
			/>
			{/* Panel Nuevo */}
			<NuevoUsuario
				mostrar={accionActual.accion === 'nuevo'}
				guardar={nuevoUsuario}
				cancelar={cerrarAccion}
				tiposDisponibles={tipos}
			/>
			{/* Panel Ver */}
			<VerUsuario
				mostrar={accionActual.accion === 'ver'}
				usuario={accionActual.usuario}
				cancelar={cerrarAccion}
				tiposDisponibles={tipos}
			/>
			{/* Panel Editar */}
			<EditarUsuario
				mostrar={accionActual.accion === 'editar'}
				usuarioOriginal={accionActual.usuario}
				cancelar={cerrarAccion}
				guardar={editarUsuario}
				mostrarTipo={false}
				tiposDisponibles={tipos}
			/>
			{/* Modal Delete */}
			<EliminarUsuario
				mostrar={accionActual.accion === 'eliminar'}
				usuario={accionActual.usuario}
				cancelar={cerrarAccion}
				eliminar={eliminarUsuario}
			/>
		</Container>
	);
}

export default Administradores