import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'


function DesmarcarFinalizarDemanda(props) {
	const { demanda, onClose, desmarcarFinalizar } = props

	const handleClose = () => {
		onClose()
	}
	
	return (
		<Modal show={true} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Marcar demanda como no finalizada</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Alert variant="warning">
					Está a punto de marcar la demanda <strong>{`${demanda.titulo}`}</strong> como no finalizado. La demanda dejará de figurar en el historial y volverá a ser mostrado en el sitio web.
				</Alert>
				<p>¿Está seguro que desea continuar?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={handleClose}>
					Cancelar
				</Button>
				<Button variant="primary" onClick={() => desmarcarFinalizar(demanda.id)}>
					No finalizado
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default DesmarcarFinalizarDemanda