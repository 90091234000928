import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { FormLabel, Offcanvas } from 'react-bootstrap'
import { PanelFooter, PanelHeader } from 'components/Panel'
import FileUpload from 'components/FileUpload/FileUpload'
import { ALIADOS_TIPOS } from 'config/aliados'

function EditarAliado(props) {
	const { aliadoOriginal, guardar, setQueMostrar } = props

	const [datosAliado, setDatosAliado] = useState({})

	const tiposAliados = ALIADOS_TIPOS

	useEffect(() => {
		setDatosAliado({
			id: aliadoOriginal.id,
			nombre: aliadoOriginal.nombre,
			email: aliadoOriginal.email,
			tipo: aliadoOriginal.tipo,
			web: aliadoOriginal.web,
			orden: aliadoOriginal.orden
		})

	}, [aliadoOriginal])

	const handleClose = () => {
		setQueMostrar('')
		setDatosAliado({})
	}

	const handleProcesar = (e) => {
		e.preventDefault()

		const data = {
			...datosAliado,
		}

		guardar(data)
		handleClose()
	}

	const handleChange = (value, key) => {
		const datosCopia = { ...datosAliado }
		datosCopia[key] = value
		setDatosAliado(prev => datosCopia)
	}

	const handleSeleccionImagen = (files) => {
		setDatosAliado({ ...datosAliado, imagen: files[0] })
	}

	// render
	return (
		<Offcanvas show={true} onHide={handleClose} placement="end" scroll="true">
			<PanelHeader onClick={handleClose} iconClass="fa-arrow-left" btnText="Atrás" text="Nuevo aliado" />
			<Offcanvas.Body>
				<h1>Datos del aliado</h1>
				{datosAliado.id && (
					<Row>
						<Col sm={12} md={6}>
							<Form onSubmit={handleProcesar} className="ms-4">
								<Row>
									<Col>
										<Form.Group className="mb-3" controlId="aliado-nombre">
											<FormLabel>Nombre</FormLabel>
											<Form.Control
												value={datosAliado.nombre}
												type="text"
												onChange={(e) => handleChange(e.target.value, 'nombre')}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form.Group className="mb-3" controlId="aliado-web">
											<FormLabel>Web</FormLabel>
											<Form.Control
												value={datosAliado.web}
												type="text"
												onChange={(e) => handleChange(e.target.value, 'web')}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form.Group className="mb-3">
											<FormLabel>Tipo</FormLabel>
											{tiposAliados.map((tipo) => (
												<Form.Check
													key={`radio-tipo-${tipo}`}
													id={`radio-tipo-${tipo}`}
													type="radio"
													label={tipo}
													name="datosAliadoTipo"
													value={tipo}
													onChange={(e) => handleChange(e.target.value, 'tipo')}
													checked={datosAliado.tipo === tipo}
												/>
											))}
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form.Group className="mb-3" controlId="aliado-email">
											<FormLabel>Email</FormLabel>
											<Form.Control
												value={datosAliado.email}
												type="text"
												onChange={(e) => handleChange(e.target.value, 'email')}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form.Group className="mb-3 col-md-3" controlId="aliado-orden">
											<FormLabel>Orden</FormLabel>
											<Form.Control
												value={datosAliado.orden}
												type="number"
												onChange={(e) => handleChange(e.target.value, 'orden')}
											/>
										</Form.Group>
									</Col>
								</Row>
							</Form>
						</Col>
						<Col sm={12} md={6}>
							{aliadoOriginal.multimedia && (
								<div className="text-center logo-actual">
									<h4>Logo seleccionado</h4>
									<img src={process.env.REACT_APP_API_URL + 'uploads/' + aliadoOriginal.multimedia.url} alt="Logo" />
									<hr className="mb-5" />
								</div>
							)}

							<FileUpload
								title="Cambiar logo"
								accept=".png, .jpg, .jpeg"
								buttonText="Elegir"
								buttonTextSingular="imagen"
								updateFiles={handleSeleccionImagen}
							/>
						</Col>
					</Row>
				)}
			</Offcanvas.Body>
			<PanelFooter>
				<Button variant="outline-tertiary" onClick={handleClose} className="me-2">
					Cancelar
				</Button>
				<Button variant="primary" onClick={handleProcesar}>
					Guardar
				</Button>
			</PanelFooter>
		</Offcanvas>
	)
}

export default EditarAliado