export const extraerTel = (string, codigoPais = '+595') => {
	// Elimina todos los caracteres no numéricos
	const cleanedNumber = string.replace(/\D/g, '')

	// Si el número comienza con '0', quita ese cero
	const formattedNumber = cleanedNumber.startsWith('0')
	  ? cleanedNumber.slice(1)
	  : cleanedNumber
  
	// Añade el prefijo internacional '+595'
	return `${codigoPais}${formattedNumber}`
}

// recibe un string con un número de teléfono en el formato proveído por extraerTel
export const agregarEspaciosTel = (string) => {
	let newStr = string.split('')
	newStr.splice(4, 0, ' ')
	newStr.splice(8, 0, ' ')
	
	return newStr
}