import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import DropdownButton from 'react-bootstrap/DropdownButton'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import { LoadingSpinner } from 'components/LoadingSpinner'
import Paginacion from 'components/Paginacion'

export default function ListaUsuarios(props) {
	const {
		loading,
		usuarios,
		numResultados,
		fetchDeACuantos,
		pagina,
		cambiarPagina,
		acciones
	} = props

	return (
		<div className="lista-usuarios">
			<Row>
				<Col>
					<small>Total resultados: {loading ? (<span className="ms-2"><LoadingSpinner size='sm' /></span>) : (<strong>{numResultados}</strong>)}</small>
				</Col>
			</Row>
			<Row>
				<Col>
					<Table id="tabla-usuarios" striped bordered hover size="sm">
						<thead>
							<tr>
								<th>Id</th>
								<th>Usuario</th>
								<th>Nombre</th>
								<th>Email</th>
								<th>Tipo</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{
								loading ? (
									<tr>
										<td colSpan="6">
											<LoadingSpinner />
										</td>
									</tr>
								) : (
									usuarios.length ? (
										usuarios.map((usuario, i) => {
											return (
												<tr key={`usuario-${usuario.id}`} className={usuario.suspendido ? 'usuario-suspendido' : ''}>
													<td>{usuario.id}</td>
													<td>{usuario.usuario}</td>
													<td>{`${usuario.apellido}, ${usuario.nombre}`}</td>
													<td>{usuario.email || '-'}</td>
													<td>{usuario.tipo}</td>
													<td className="text-start">
														<DropdownButton
															as={ButtonGroup}
															title="OPCIONES"
															id="bg-nested-dropdown"
															className="fst-normal"
															size="sm"
														>
															{acciones.handleVer && (<Dropdown.Item
																onClick={() => acciones.handleVer(usuario)}
																eventKey="ver"
															>
																Ver
															</Dropdown.Item>)}
                                                            {acciones.handleCambiarContrasena && (<Dropdown.Item
																onClick={() => acciones.handleCambiarContrasena(usuario)}
																eventKey="cambiar-contrasena"
															>
																Cambiar contraseña
															</Dropdown.Item>)}
															{acciones.handleEditar && (<Dropdown.Item
																onClick={() => acciones.handleEditar(usuario)}
																eventKey="editar"
															>
																Editar
															</Dropdown.Item>)}
															{acciones.handleSuspender && (<Dropdown.Item
																onClick={() => acciones.handleSuspender(usuario)}
																eventKey="suspender"
															>
																{usuario.suspendido ? 'Levantar suspensión' : 'Suspender'}
															</Dropdown.Item>)}
															{acciones.handleEliminar && (<Dropdown.Item
																onClick={() => acciones.handleEliminar(usuario)}
																eventKey="eliminar"
															>
																Eliminar
															</Dropdown.Item>)}
														</DropdownButton>
													</td>
												</tr>
											)
										})
									) : (
										<tr>
											<td colSpan="6">
												No se encontraron usuarios.
											</td>
										</tr>
									)
								)
							}
						</tbody>
					</Table>
				</Col>
			</Row>
			<Row>
				<Col>
					<Paginacion
						pagina={pagina}
						numPaginas={Math.ceil(numResultados / fetchDeACuantos) || 1}
						setPagina={cambiarPagina}
					/>
				</Col>
			</Row>
						
		</div>
	)
}