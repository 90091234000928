import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'


function RechazarConsultoria(props) {
	const { consultoria,setQueMostrar, rechazar } = props

	const handleClose = () => {
		setQueMostrar('')
	}
	
	return (
		<Modal show={true} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Rechazar Consultoría</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Alert variant="danger">
					Está a punto de marcar como rechazada la consultoría <strong>{`${consultoria.titulo}`}</strong>.
				</Alert>
				<p>¿Está seguro que desea continuar?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={handleClose}>
					Cancelar
				</Button>
				<Button variant="primary" onClick={() => rechazar(consultoria.id)}>
					Rechazar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default RechazarConsultoria