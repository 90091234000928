import React, { useState, useEffect } from 'react'
import Alert from 'react-bootstrap/Alert'
import Accordion from 'react-bootstrap/Accordion'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify'

import api from 'api/api'
import Parte from 'components/Modificaciones/Parte'
import { isEmpty } from 'utils/isEmpty'

function Seccion(props) {
	const { nombreSeccion } = props
	const [loading, setLoading] = useState(true)
	const [datos, setDatos] = useState({})

	const gruposUnitariosIds = datos.gruposPartes?.filter(grupo => grupo.partes.length === 1).map(grupo => grupo.id) ?? {}

	useEffect(() => {
		getDatos()
	}, [])

	const getDatos = async () => {
		try {
			setLoading(true)
			const { datos } = await api.get('p/datos-seccion?seccion=' + nombreSeccion)

			if (datos.length) {
				setDatos(datos[0])
			} else {
				setDatos({})
			}
		} catch (error) {
			toast.error('Error de conexión')
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const armarDatosParaServer = (dato, forceUseFormData = false) => {
		let useFormData = false

		// Verifica si hay que subir archivos, en cuyo caso utiliza FormData en vez del body normal del POST		
		if (forceUseFormData) {
			useFormData = true
		} else {
			if (!isEmpty(dato.upload)) {
				useFormData = true
			}
		}

		let serverData = dato
		if (useFormData) {
			/*
			Se arma un FormData con la siguiente estructura:
			{
				'id' => id // si es que hay
				'grupo' => nombre
				'tipo': tipo
				'key': key
				'valor': valor
			}
			*/

			serverData = new FormData();

			if (typeof dato.id !== 'undefined') {
				serverData.append('componentes', dato.id)
			}

			serverData.append('grupo', dato.grupo)
			serverData.append('tipo', dato.tipo)
			serverData.append('key', dato.key)
			serverData.append('valor', dato.valor)

			if (!isEmpty(dato.upload)) {
				serverData.append(dato.upload.key, dato.upload.files[0])
			}
		}

		return serverData
	}

	const armarDatosGrupoParaServer = (dato, forceUseFormData = false) => {
		let useFormData = false

		// Verifica si hay que subir archivos, en cuyo caso utiliza FormData en vez del body normal del POST		
		if (forceUseFormData) {
			useFormData = true
		} else {
			if (Array.isArray(dato)) {
				dato.forEach((componente) => {
					if (!isEmpty(componente.upload)) {
						useFormData = true
					}
				})
			} else {
				if (!isEmpty(dato.upload)) {
					useFormData = true
				}
			}
		}

		let serverData = dato
		if (useFormData) {
			/*
			Se arma un FormData con la siguiente estructura:
			{
				'grupo' => nombre
				'file1' => archivo
				'file2' => archivo cada archivo con su key
				'componentes' => [
					'tipo': tipo
					'key': key
					'valor': valor
				]
			}
			De esta manera el server puede identificar el grupo, agregar todos los archivos y luego cada uno de los componentes 
			*/

			serverData = new FormData();
			const componentes = []
			let grupo = ''
			dato.forEach((componente) => {
				grupo = componente.grupo
				const nuevoComponente = {
					tipo: componente.tipo,
					key: componente.key,
					valor: componente.valor
				}

				if (typeof componente.id !== 'undefined') {
					nuevoComponente.id = componente.id
				}

				componentes.push(nuevoComponente)
				if (!isEmpty(componente.upload)) {
					serverData.append(componente.upload.key, componente.upload.files[0])
				}
			})
			serverData.append('grupo', grupo)
			serverData.append('componentes', JSON.stringify(componentes))
		}

		return serverData
	}

	const agregarDatoParte = async (dato, tipo) => {
		let url = 'a/modificaciones/' + dato[0].parteId + '/'

		switch (tipo) {
			case 'texto-corto':
				url += 'texto'
				break
			default: // imagen, video, grupo
				url += tipo
		}

		const serverData = tipo === 'grupo' ? armarDatosGrupoParaServer(dato, true) : armarDatosParaServer(dato)

		try {
			setLoading(true)
			const { datos: respuesta } = await api.post(url, serverData)

			toast.success('Elemento agregado exitosamente')
			getDatos()
		} catch (error) {
			setLoading(false)
			toast.error('Error de conexión')
			console.error(error)
		}
	}

	const editarDatoParte = async (dato, tipo) => {
		const parteId = Array.isArray(dato) ? dato[0].parteId : dato.parteId
		let url = 'a/modificaciones/' + parteId + '/'

		switch (tipo) {
			case 'texto-corto':
				url += 'texto'
				break
			default: // imagen, video, grupo
				url += tipo
		}

		const serverData = tipo === 'grupo' ? armarDatosGrupoParaServer(dato, true) : armarDatosParaServer(dato)

		try {
			setLoading(true)
			let respuesta = false
			if (tipo === 'grupo') {
				respuesta = await api.post(url + '/' + dato[0].grupo, serverData)
			} else {
				if (tipo === 'imagen') {
					respuesta = await api.post(url + '/' + dato.id, serverData)
				} else {
					respuesta = await api.put(url + '/' + dato.id, serverData)
				}
			}

			toast.success('Elemento editado exitosamente')
			getDatos()
		} catch (error) {
			setLoading(false)
			toast.error('Error de conexión')
			console.error(error)
		}
	}

	// Si porGrupo === true, entonces key es el nombre del grupo. Si no, es el id del valor a eliminar
	const eliminarDatoParte = async (parteId, key, tipo) => {
		let url = 'a/modificaciones/' + parteId + '/'
		switch (tipo) {
			case 'texto-corto':
				url += 'texto'
				break
			default: // imagen, video, grupo
				url += tipo
		}

		url += '/' + key

		try {
			setLoading(true)
			const { datos: respuesta } = await api.delete(url)

			toast.success('Elemento eliminado exitosamente')
			getDatos()
		} catch (error) {
			setLoading(false)
			toast.error('Error de conexión')
			console.error(error)
		}
	}

	// render
	return (
		<div className="seccion">
			{loading ? (
				<Spinner
					animation="border"
					size="lg"
					role="status"
					aria-hidden="true"
					variant="primary"
					className="my-3"
				/>
			) : (
				<>
					{Object.keys(datos).length === 0 ? (
						<Alert variant="warning">No hay datos modificables en la sección <strong>{nombreSeccion}</strong>.</Alert>
					) : (
						<>
							<h2>{datos.nombre}</h2>
							<sub>{datos.fechaCreacion}</sub>

							<h3 className="mt-2">Elementos de la sección</h3>
							<Accordion defaultActiveKey={gruposUnitariosIds} alwaysOpen>
								{
									datos.gruposPartes.map(grupoParte => {

										return (
											<Accordion.Item key={`grupo-${grupoParte.id}`} eventKey={grupoParte.id}>
												<Accordion.Header>{grupoParte.nombre}</Accordion.Header>
												<Accordion.Body>
													{
														grupoParte.partes.map(parte => {
															return (
																<Parte
																	key={parte.id}
																	original={parte}
																	agregar={agregarDatoParte}
																	editar={editarDatoParte}
																	eliminar={eliminarDatoParte}
																/>
															)
														})
													}
												</Accordion.Body>
											</Accordion.Item>
										)
									})
								}
							</Accordion>
						</>
					)}
				</>
			)}
		</div>
	)
}

export default Seccion