import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'


function CambiarEstadoConsultoria(props) {
	const { consultoria, setQueMostrar, cambiarEstado } = props

	const [contactado, setContactado] = useState(consultoria.estado === 'contactado' || consultoria.estado === 'aceptado')
	const [aceptado, setAceptado] = useState(consultoria.estado === 'aceptado')

	const estadoActual = aceptado ? 'aceptado' : (contactado ? 'contactado' : 'recibido')

	const handleClose = () => {
		setQueMostrar('')
	}

	return (
		<Modal show={true} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Cambiar estado</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{/* contenido acá */}
				<Form.Group className="mb-3" controlId="recibido">
					<Form.Check
						type="switch"
						label="Recibido"
        				checked={true}
						disabled={true}
					/>
				</Form.Group>
				<Form.Group className="mb-3" controlId="contactado">
					<Form.Check
						type="switch"
						label="Contactado"
        				checked={contactado}
						onChange={(e) => setContactado(Boolean(e.target.checked))}
						disabled={aceptado}
					/>
				</Form.Group>
				<Form.Group className="mb-3" controlId="aceptado">
					<Form.Check
						type="switch"
						label="Aceptado"
        				checked={aceptado}
						onChange={(e) => setAceptado(Boolean(e.target.checked))}
						disabled={!contactado}
					/>
				</Form.Group>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={handleClose}>
					Cancelar
				</Button>
				<Button
					variant="primary"
					onClick={() => cambiarEstado(consultoria.id, estadoActual)}
					disabled={estadoActual === consultoria.estado}
				>
					Cambiar estado
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default CambiarEstadoConsultoria