import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'


function FinalizarProyecto(props) {
	const { proyecto, onClose, finalizar } = props

	const handleClose = () => {
		onClose()
	}
	
	return (
		<Modal show={true} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Finalizar proyecto</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Alert variant="warning">
					Está a punto de finalizar el proyecto <strong>{`${proyecto.nombre}`}</strong>. El proyecto dejará de mostrarse en el sitio web.
				</Alert>
				<p>¿Está seguro que desea continuar?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={handleClose}>
					Cancelar
				</Button>
				<Button variant="primary" onClick={() => finalizar(proyecto.id)}>
					Finalizar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default FinalizarProyecto