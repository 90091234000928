import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'


function ComentarDemanda(props) {
	const { demanda, onClose, comentar } = props

	const [comentario, setComentario] = useState('')

	const handleClose = () => {
		onClose()
	}

	const handleComentar = () => {
		comentar(demanda.id, comentario)
	}

	return (
		<Modal show={true} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Comentar demanda</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className="mb-3" controlId="txtComentario">
						<Form.Label><strong>Comentario</strong></Form.Label>
						<Form.Control
							as="textarea"
							rows={3}
							value={comentario}
							onChange={(e) => setComentario(e.target.value)}
						/>
					</Form.Group>
				</Form>
				<Alert variant="warning">
					Está a punto de comentar la demanda <strong>{`${demanda.titulo}`}</strong>. El usuario que creó la demanda verá el cambio de estado y comentario.
				</Alert>
				<p>¿Está seguro que desea continuar?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={handleClose}>
					Cancelar
				</Button>
				<Button variant="primary" onClick={handleComentar} disabled={comentario.trim() === ''}>
					Comentar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default ComentarDemanda