import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Calendario from './Calendario'
import { LoadingSpinner } from 'components/LoadingSpinner'

const TabCalendario = (props) => {
	const { loading, proyectos } = props

	return (
		<Row>
			<Col>
				{loading ? (
					<LoadingSpinner />
				) : (
					<Calendario proyectos={proyectos} />
				)}
			</Col>
		</Row>
	)
}

export default TabCalendario