import React from 'react';

const Thumb = ({ url, alt, size = 80 }) => {
    if (url) {
        return (
            <div
                style={{
                    width: size,
                    height: size,
                    display: 'flex',
                    backgroundColor: '#F8F8FA',
                    border: 'solid #E8E8EC 1px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden', // Agregamos overflow hidden para ocultar las partes que no se ajustan
                }}
            >
                <img
                    src={url}
                    alt={alt}
                    style={{
                        objectFit: 'contain', // Ajusta la imagen para que se centre y se recorte si es necesario
                        width: '100%',
                        height: '100%',
                    }}
                />
            </div>
        );
    } else {
        return (
            <div
                style={{
                    width: size,
                    height: size,
                    backgroundColor: '#ccc',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px',
                }}
            >
                <i className="fa fa-image"></i>
            </div>
        );
    }
};

export default Thumb;