import { useState, useCallback, useEffect } from 'react'

/*
 * La función validate es algo como:
 const validate = (data) => {
		const newErrors = {}
		let firstInvalidId = null

		// Cada campo debe tener un id único que coincida con su nombre
		Object.keys(data).forEach((key) => {
			const error = validateField(key, data[key])
			if (error && !firstInvalidId) {
				// Guarda el id del primer campo inválido
				firstInvalidId = key
			}
			if (error) {
				newErrors[key] = error
			}
		})

		return { errors: newErrors, firstInvalidId: firstInvalidId }
	}
*/
export function useForm(initialState, validate, onSuccess, options = {}) {
	const defaultOptions = {
		touched: false
	}

	const mergedOptions = { ...defaultOptions, ...options }

	const [data, setData] = useState(initialState)
	const [errors, setErrors] = useState({})
	const [touched, setTouched] = useState(mergedOptions.touched)
	const [modified, setModified] = useState({})

	const reset = () => {
		setModified({})
	}

	const updateInitialData = (datos) => {
		setData({...datos})
	}

	const handleChange = useCallback((value, name) => {
		setData((prevData) => {
			const isModified = value !== initialState[name]
			setModified((prevModified) => ({ ...prevModified, [name]: isModified }))
			return { ...prevData, [name]: value }
		})
		if (touched) {
			const newErrors = validate({ ...data, [name]: value })
			setErrors(newErrors.errors)
		}
	}, [data, touched, validate])

	const handleSubmit = useCallback((event) => {
		if (event) {
			event.preventDefault()
		}

		const { errors, firstInvalidId } = validate(data)
		setErrors(errors)
		setTouched(true)

		if (firstInvalidId) {
			document.getElementById(firstInvalidId).focus()
		} else {
			onSuccess(data)
		}
	}, [data, validate, onSuccess])

	return {
		data,
		handleChange,
		handleSubmit,
		errors,
		modified,
		reset,
		updateInitialData,
		setTouched
	}
}