import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Image from 'react-bootstrap/Image'
import { agregarEspaciosTel, extraerTel } from 'utils/telefono'
import { formatDate, formatTime } from 'utils/date'
import { Avatar } from 'components/Avatar'

const ApoyoPopup = (props) => {
	const { apoyo, onClose } = props

	console.log('apoyo', apoyo)

	const handleClose = () => {
		onClose()
	}

	const getSectores = (sectores) => {

		return (
			<div className="oferta-lista-sectores">
				{sectores.map(sector => (
					<span key={`sector-${sector.id}`} className="oferta-sector">{sector.nombre}</span>
				))}
			</div>
		)
	}

	const apoyoDate = new Date(apoyo.fechaApoyo)

	return (
		<Modal show={true} size="md" onHide={handleClose} className="apoyo-modal">
			<Modal.Header closeButton>
				<Modal.Title><h2 className="sin-subrayado">Apoyo</h2></Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="d-flex gap-3 align-items-center">
					<div>
						<Avatar name={apoyo.nombre} url={apoyo.avatar ? process.env.REACT_APP_API_URL + apoyo.avatar : null} size={100} />
					</div>
					<div className="detalles-apoyo d-flex flex-column">
						<div>
							{`${apoyo.nombre} ${apoyo.apellido}`}
						</div>
						<div>
							<a href={`mailto:${apoyo.usuario}`}>{apoyo.usuario}</a>
						</div>
						<div>
							<a href={`tel:${extraerTel(apoyo.telefono)}`}>{agregarEspaciosTel(apoyo.telefono)}</a>
						</div>
						<div>
							{/*getSectores(apoyo.areas)*/}
							{apoyo.apoyoTipo}
						</div>
					</div>
				</div>
				<div>
					<small className="text-muted">Fecha de apoyo: {`${formatDate(apoyoDate)}, a las ${formatTime(apoyoDate)}`}</small>
				</div>


			</Modal.Body>
		</Modal>
	)
}

export default ApoyoPopup