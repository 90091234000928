import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Table'
import { PanelFooter, PanelHeader } from 'components/Panel'

import './demandas.css'
import { Offcanvas } from 'react-bootstrap'
import ContactoItem from './ContactoItem'
import ContactoPopup from './ContactoPopup'

function VerDemanda(props) {
	const { demanda, onClose, mostrarContactos = false } = props

	const [selectedContacto, setSelectedContacto] = useState(null)

	const handleSelectContacto = (contacto) => {
		setSelectedContacto(contacto)
	}

	const cerrarContacto = () => {
		setSelectedContacto(null)
	}

	const handleClose = () => {
		onClose()
	}

	const areasStr = demanda.areas.map(area => area.nombre).join(', ')

	// render
	return (
		<Offcanvas show={true} onHide={handleClose} placement="end" scroll="true">
			<PanelHeader onClick={handleClose} iconClass="fa-arrow-left" btnText="Atrás" text="Ver demanda" />
			<Offcanvas.Body>
				<h1>Datos de la demanda</h1>
				<Row>
					<Col sm={6} md={6}>
						<Table>
							<thead></thead>
							<tbody>
								<tr>
									<th>Id</th>
									<td>{demanda.id}</td>
								</tr>
								<tr>
									<th>Usuario</th>
									<td>{demanda.usuario.nombre}</td>
								</tr>
								<tr>
									<th>Título</th>
									<td>{demanda.titulo}</td>
								</tr>
								<tr>
									<th>Presentado</th>
									<td>{demanda.fechaPresentado}</td>
								</tr>
								<tr>
									<th>Áreas</th>
									<td>{areasStr}</td>
								</tr>
							</tbody>
						</Table>
						<h2>Necesidad</h2>
						{demanda.necesidad}
						<h2 className="mt-3">Experiencia</h2>
						{demanda.experiencia}
					</Col>
					{mostrarContactos ? (
						<Col>
							<h3 className="sin-subrayado text-start mt-3">Contactos</h3>
							{demanda.contactos.length ? (
								demanda.contactos.map(contacto => {

									return (
										<ContactoItem key={contacto.id} contacto={contacto} onSelect={handleSelectContacto} />
									)
								})
							) : (
								<Alert>Todavía no hay contactos para esta demanda.</Alert>
							)}
						</Col>
					) : null}
				</Row>
			</Offcanvas.Body>
			<PanelFooter>
				<Button variant="primary" onClick={handleClose}>
					Ok
				</Button>
			</PanelFooter>
			{selectedContacto && <ContactoPopup contacto={selectedContacto} onClose={cerrarContacto} />}
		</Offcanvas>
	)
}

export default VerDemanda