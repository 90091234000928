import React from 'react'
import ListaProyectos from '../ListaProyectos'
import VerProyecto from '../VerProyecto'
import FinalizarProyecto from '../FinalizarProyecto'

const TabListado = (props) => {
	const { loading, proyectos, numResultados, pagina, fetchDeACuantos, cambiarPagina, acciones, accionActual, cerrarAccion, finalizar } = props

	return (
		<>
			<ListaProyectos
				loading={loading}
				proyectos={proyectos}
				numResultados={numResultados}
				pagina={pagina}
				fetchDeACuantos={fetchDeACuantos}
				cambiarPagina={cambiarPagina}
				acciones={acciones}
				conProgreso
			/>
			{
				accionActual.accion === 'ver' ? (
					<VerProyecto
						proyecto={proyectos.find(p => p.id === accionActual.proyectoId)}
						onClose={cerrarAccion}
					/>
				) : null
			}
			{
				accionActual.accion === 'finalizar' ? (
					<FinalizarProyecto
						proyecto={proyectos.find(p => p.id === accionActual.proyectoId)}
						onClose={cerrarAccion}
						finalizar={finalizar}
					/>
				) : null
			}
		</>
	)
}

export default TabListado