import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import api from 'api/api'
import { toast } from 'react-toastify'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client';

import './estadisticas.css'

function Estadisticas() {
	const [loading, setLoading] = useState(false)
	const [datos, setDatos] = useState(null)

	return (
		<Container fluid id="estadisticas" className="vh-100">
			<Row>
				<Col style={{ height: '100vh' }}>
				<iframe title="Estadísticas Conectivo" width={'100%'} height={'100%'} src="https://app.powerbi.com/view?r=eyJrIjoiNzg3MjVkODQtYTI4ZC00MGQxLTk0MjktYWVjMmRjNjM2OGRlIiwidCI6IjBmODNlNDQ5LTdlNmItNDNiNS04NWU2LTI2NjU5ZTY2OThlNyIsImMiOjR9" frameborder="0" allowFullScreen="true"></iframe>
				</Col>
			</Row>
		</Container>
	)
}

export default Estadisticas