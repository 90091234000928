import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import '../Seccion.css'
import { InfoIcon } from 'components/InfoIcon'

const Link = (props) => {
	const { parte, editar } = props
	const [error, setError] = useState('')
	const [componente, setComponente] = useState({
		id: '-',
		tipo: 'texto-corto',
		valor: ''
	})
	const [indicaciones, setIndicaciones] = useState(null)
	const [modoEdicion, setModoEdicion] = useState(false)
	const [linkExterno, setLinkExterno] = useState(false)
	const [esValido, setEsValido] = useState(true)

	useEffect(() => {
		if (typeof parte.grupos['~'] === 'undefined' || typeof parte.grupos['~'][parte.key] === 'undefined' || parte.grupos['~'][parte.key].length !== 1 || !parte.grupos['~'][parte.key][0].id) {
			setError('Parte no inicializada correctamente en la base de datos')
		} else {
			setComponente({
				id: parte.grupos['~'][parte.key][0].id,
				tipo: 'texto-corto',
				parteId: parte.id,
				valor: parte.grupos['~'][parte.key][0].valor || ''
			})
			setIndicaciones(parte.indicaciones)
		}
	}, [parte])

	const handleChange = (valor) => {
		const componenteCopy = { ...componente }
		componenteCopy.valor = valor

		setComponente(componenteCopy)

		if (validarLink(valor, linkExterno)) {
			setEsValido(true)
		} else {
			setEsValido(false)
		}
	}

	const handleGuardar = () => {
		editar(componente, 'texto-corto')
		setModoEdicion(false)
	}

	const handleCheckboxChange = (checked) => {
		setLinkExterno(checked)

		setEsValido(validarLink(componente.valor, checked))
	}

	function validarLink(url, checked) {
		// Expresión regular para enlaces externos
		const regexExterno = /^(ht|f)tp(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-\.\?\,\'\/\\\+&amp;%\$#_]*)?$/;
		// Expresión regular para enlaces internos (rutas relativas o absolutas)
		const regexInterno = /^[A-Za-z0-9\/\.\-\_\#\?\=\&\%]*$/;

		if (checked) {
			return regexExterno.test(url)
		} else {
			return (regexInterno.test(url)) || url === '' // Permitir cadena vacía para enlaces internos
		}
	}

	return (
		<Container fluid style={{ padding: '0 12px' }}>
			<Row className="modificacionesParte" key={`parte-${parte.id}`}>
				<Col sm={12} md={6} lg={4} className="modificacionesParteWrapper">
					<div className="modificacionesParteHeader">
						<div className="modificacionesNombre">{parte.nombre}</div>
						<div className="modificacionesTipo">, {parte.tipo}</div>
						{!modoEdicion && <Button
							onClick={() => setModoEdicion(true)}
						>
							<i title="Modificar" className="fas fa-pencil"></i> Modificar
						</Button>}
					</div>
					<div className="modificacionesDescripcion">{parte.descripcion}</div>
					<div className="modificacionesParteTextoCorto">
						{error !== '' ? (
							<Col className="mt-3">
								<Alert variant="danger"><i className="fas fa-exclamation-triangle"></i> {error}</Alert>
							</Col>
						) : (
							<Col>
								{indicaciones && <Alert variant="info" className="mt-3">{indicaciones}</Alert>}
								{modoEdicion ? (
									<div className="modificar-valor-wrapper">
										<Form.Check
											type="checkbox"
											id="check-externo"
											label={<>Es enlace externo <InfoIcon>Los enlaces externos deben tener un formato válido de URL absoluta. Ej.: "http(s)://google.com"</InfoIcon></>}
											onChange={(e) => handleCheckboxChange(e.target.checked)}
										/>
										<Form.Group className="mb-3" controlId={`parte-${parte.id}`}>
											<Form.Control
												value={componente.valor}
												type="text"
												onChange={(e) => handleChange(e.target.value)}
												isInvalid={!esValido}
											/>
											<Form.Control.Feedback type="invalid">
												Por favor, introduce un enlace válido.
											</Form.Control.Feedback>
										</Form.Group>
										<div className="botones">
											<Button
												onClick={() => setModoEdicion(false)}
												variant="outline-tertiary"
												className="me-2"
											>
												<i title="Cancelar" /> Cancelar
											</Button>
											<Button
												onClick={handleGuardar}
												disabled={!esValido}
											>
												<i title="Guardar" className="fas fa-save"></i> Guardar
											</Button>
										</div>
									</div>
								) : (
									<div className="valor-actual-wrapper">
										<div className="valor-actual">{componente.valor || '-'}</div>
									</div>
								)}
							</Col>
						)}
					</div>
				</Col>
			</Row>
		</Container>
	);
}

export default Link