import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { isLoggedIn } from './utils/general'
import SideMenu from 'components/SideMenu/SideMenu'

import NoMatch from './pages/NoMatch'
import Login from './pages/login/Login'
import Home from './pages/home/Home'
import { Administradores, Usuarios } from './pages/usuarios'
import { Contacto, Inicio, QuienesSomos, Conecta, Ejes } from './pages/modificaciones'
import { TestComponent } from 'pages/test'
import { Socios } from 'pages/socios'
import { Aliados } from 'pages/aliados'
import { ProyectosConfigProvider } from 'contexts/ProyectosConfigProvider'
import { DemandasConfigProvider } from 'contexts/DemandasConfigProvider'
import { OfertasConfigProvider } from 'contexts/OfertasConfigProvider'
import { ProyectosActuales, ProyectosHistorial, ProyectosPendientes } from 'pages/proyectos'
import { DemandasActuales, DemandasHistorial, DemandasPendientes } from 'pages/demandas'
import { OfertasActuales, OfertasHistorial } from 'pages/ofertas'
import { Sectores } from 'pages/sectores'
import { Estadisticas } from 'pages/estadisticas'
import { Consultorias } from 'pages/consultorias'
import { ProyectosEnCurso } from 'pages/proyectos/ProyectosEnCurso'
import { ConectivoBeneficios, ConectivoComoFunciona, ConectivoInicio } from 'pages/modificaciones/conectivo/'

function App() {
	// login state
	const [loggedIn, setLoggedIn] = useState(isLoggedIn());
	const [menuExpanded, setMenuExpanded] = useState(true);

	if (menuExpanded) {
		document.body.classList.add('menu-expanded');
	} else {
		document.body.classList.remove('menu-expanded');
	}

	useEffect(() => {
		setLoggedIn(isLoggedIn());
	}, []);

	// render
	return (
		<>
			<ToastContainer />
			<Router basename={process.env.PUBLIC_URL}>
				<Route render={({ location, history }) => (
					<>
						<Route exact path="/login" component={props => <Login setLoggedIn={setLoggedIn} history={history} />} />
						{loggedIn && (
							<>
								<SideMenu location={location} history={history} setLoggedIn={setLoggedIn} menuExpanded={menuExpanded} setMenuExpanded={setMenuExpanded} />
								<main className={menuExpanded ? 'section expanded' : 'section'}>
									<ProyectosConfigProvider>
										<DemandasConfigProvider>
                                            <OfertasConfigProvider>
                                                <Switch>
                                                    <Route exact path="/" render={() => <Redirect to="/inicio" />} />
                                                    <Route exact path="/inicio" component={Home} />

                                                    <Route exact path="/usuarios/administradores">
                                                        <Administradores />
                                                    </Route>
                                                    <Route exact path="/usuarios/conectivo">
                                                        <Usuarios />
                                                    </Route>

                                                    <Route exact path="/socios" component={Socios} />
                                                    <Route exact path="/aliados" component={Aliados} />
                                                    <Route exact path="/sectores" component={Sectores} />
                                                    <Route exact path="/consultorias" component={Consultorias} />

													{/* Demandas */}
													<Route exact path="/demandas/pendientes" component={DemandasPendientes} />
                                                    <Route exact path="/demandas/actuales" component={DemandasActuales} />
                                                    <Route exact path="/demandas/historial" component={DemandasHistorial} />

                                                    {/* Ofertas */}
                                                    <Route exact path="/ofertas/actuales" component={OfertasActuales} />
                                                    <Route exact path="/ofertas/historial" component={OfertasHistorial} />

													<Route exact path="/proyectos/pendientes" component={ProyectosPendientes} />
													<Route exact path="/proyectos/actuales" component={ProyectosActuales} />
													<Route exact path="/proyectos/en-curso" component={ProyectosEnCurso} />
													<Route exact path="/proyectos/historial" component={ProyectosHistorial} />

                                                    <Route exact path="/estadisticas" component={Estadisticas} />
                                                    {/* Rutas de modificaciones */}
                                                    <Route exact path="/modificaciones/inicio" component={Inicio} />
                                                    <Route exact path="/modificaciones/quienes-somos" component={QuienesSomos} />
                                                    <Route exact path="/modificaciones/ejes" component={Ejes} />
                                                    <Route exact path="/modificaciones/conecta" component={Conecta} />
                                                    <Route exact path="/modificaciones/contacto" component={Contacto} />
													<Route exact path="/modificaciones/conectivo-inicio" component={ConectivoInicio} />
													<Route exact path="/modificaciones/conectivo-beneficios" component={ConectivoBeneficios} />
													<Route exact path="/modificaciones/conectivo-como-funciona" component={ConectivoComoFunciona} />

                                                    <Route exact path="/test" component={TestComponent} />
                                                    {/* Ruta para cualquier otra ruta no definida */}
                                                    <Route path="*" component={NoMatch} />
                                                </Switch>
                                            </OfertasConfigProvider>
										</DemandasConfigProvider>
									</ProyectosConfigProvider>
								</main>
							</>
						)}
					</>
				)}
				/>
				{!loggedIn && <Redirect to="/login" />}
			</Router>
		</>
	);
}

export default App;
