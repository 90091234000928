import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import DropdownButton from 'react-bootstrap/DropdownButton'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import { LoadingSpinner } from 'components/LoadingSpinner'
import Paginacion from 'components/Paginacion'

export default function ListaProyectos(props) {
	const {
		loading,
		proyectos,
		numResultados,
		fetchDeACuantos,
		pagina,
		cambiarPagina,
		acciones,
		conProgreso = false
	} = props

	const hoy = new Date()
	hoy.setHours(0, 0, 0, 0)

	return (
		<div className="lista-proyectos">
			<Row>
				<Col>
					<small>Total resultados: {loading ? (<span className="ms-2"><LoadingSpinner size='sm' /></span>) : (<strong>{numResultados}</strong>)}</small>
				</Col>
			</Row>
			{/* TABLE */}
			<Row>
				<Col>
					<Table id="tabla-proyectos" striped bordered hover>
						<thead>
							<tr>
								<th>id</th>
								<th>Usuario</th>
								<th>Imagen</th>
								<th>Postulante</th>
								<th>Nombre</th>
								<th>Fecha presentado</th>
								<th># apoyos</th>
								<th># visualizaciones</th>
								{conProgreso && <th>Progreso</th>}
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{
								loading ? (
									<tr>
										<td colSpan="6">
											<LoadingSpinner />
										</td>
									</tr>
								) : (
									proyectos.length ? (
										proyectos.map((proyecto, i) => {
											let hitosCompletados = 0
											let conDemora = 0
											proyecto.hitos?.forEach(hito => {
												if (hito.completado) {
													hitosCompletados++
												} else {
													if (new Date(hito.fecha) < hoy) {
														conDemora++
													}
												}
											})

											const titleStr = conDemora ? (`${conDemora} hito${conDemora > 1 ? 's' : ''} con demora`) : ''

											return (
												<tr key={`proyecto-${proyecto.id}`} className={conDemora ? 'con-demora' : ''}>
													<td>{proyecto.id}</td>
													<td>{proyecto.usuario.nombre}</td>
													<td className="img-wrapper">
														{proyecto.multimedia ? <img src={process.env.REACT_APP_API_URL + proyecto.multimedia.url} alt="x" /> : '-'}
													</td>
													<td>{proyecto.postulante}</td>
													<td>{proyecto.nombre}</td>
													<td>{proyecto.fechaPresentado?.substr(0, 10) || '-'}</td>
													<td>{proyecto.apoyos.length}</td>
													<td>{proyecto.visualizaciones.length}</td>
													{conProgreso && <td className="hito" title={titleStr}>{`${hitosCompletados} / ${proyecto.hitos.length}`}</td>}
													<td className="text-center">
														<DropdownButton
															as={ButtonGroup}
															title="OPCIONES"
															id="bg-nested-dropdown"
															className="fst-normal"
														>
															{acciones.handleVer && (<Dropdown.Item
																onClick={() => acciones.handleVer(proyecto.id)}
																eventKey="ver"
															>
																Ver
															</Dropdown.Item>)}
															{acciones.handleAprobar && (<Dropdown.Item
																onClick={() => acciones.handleAprobar(proyecto.id)}
																eventKey="aprobar"
															>
																Aprobar
															</Dropdown.Item>)}
															{acciones.handleComentar && (<Dropdown.Item
																onClick={() => acciones.handleComentar(proyecto.id)}
																eventKey="comentar"
															>
																Comentar
															</Dropdown.Item>)}
															{acciones.handleIniciar && (<Dropdown.Item
																onClick={() => acciones.handleIniciar(proyecto.id)}
																eventKey="iniciar"
															>
																Iniciar
															</Dropdown.Item>)}
															{acciones.handleFinalizar && (<Dropdown.Item
																onClick={() => acciones.handleFinalizar(proyecto.id)}
																eventKey="finalizar"
															>
																Finalizar
															</Dropdown.Item>)}
															{acciones.handleDesmarcarFinalizar && (<Dropdown.Item
																onClick={() => acciones.handleDesmarcarFinalizar(proyecto.id)}
																eventKey="desmarcarFinalizar"
															>
																No finalizado
															</Dropdown.Item>)}
														</DropdownButton>
													</td>
												</tr>
											)
										})
									) : (
										<tr>
											<td colSpan="6">
												No se encontraron proyectos.
											</td>
										</tr>
									)
								)
							}
						</tbody>
					</Table>
				</Col>
			</Row>

			<Row>
				<Col>
					<Paginacion
						pagina={pagina}
						numPaginas={Math.ceil(numResultados / fetchDeACuantos) || 1}
						setPagina={cambiarPagina}
					/>
				</Col>
			</Row>

		</div>
	)
}