import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'

import { toast } from 'react-toastify'

import api from 'api/api'
import './consultorias.css'
import { debounce } from 'utils/debounce'
import Paginacion from 'components/Paginacion'
import VerConsultoria from './VerConsultoria'
import CambiarEstadoConsultoria from './CambiarEstadoConsultoria'
import RechazarConsultoria from './RechazarConsultoria'
import { CONSULTORIAS_ESTADOS_LABELS_MAP } from 'config/consultorias'
import DeshacerRechazarConsultoria from './DeshacerRechazarConsultoria'
import { FiltrosFormWrapper } from 'components/FiltrosFormWrapper'


function Consultorias() {
	const [fetchDeACuantos, setFetchDeACuantos] = useState(10)
	const [numResultados, setNumResultados] = useState(0)
	const [paginaAct, setPaginaAct] = useState(1)
	const [loading, setLoading] = useState(false)

	const [busqueda, setBusqueda] = useState('')
	const [filtroBusqueda, setFiltroBusqueda] = useState('')
	const [consultorias, setConsultorias] = useState([])
	const [consultoriaActual, setConsultoriaActual] = useState()
	const [queMostrar, setQueMostrar] = useState('')

	const hoy = new Date().toISOString().substring(0, 10)

	const refreshConsultorias = async () => {
		try {
			setLoading(true)
			const { datos } = await api.post('a/ver-consultorias', {
				cuantos: fetchDeACuantos,
				desde: paginaAct === 1 ? 0 : (paginaAct - 1) * fetchDeACuantos,
				filtros: {
					busqueda,
				}
			})

			setConsultorias(datos.consultorias)
			setNumResultados(datos.numResultados)
		} catch (error) {
			toast.error('Error de conexión')
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const handleBusqueda = (value) => {
		if (value) {
			setFiltroBusqueda(value)
		} else {
			setFiltroBusqueda('')
		}
	}

	const debouceFiltroBusqueda = React.useCallback(
		debounce(handleBusqueda, 400),
		[]
	)

	useEffect(() => {
		debouceFiltroBusqueda(busqueda)
	}, [busqueda])

	useEffect(() => {
		if (paginaAct === 1) {
			refreshConsultorias()
		} else {
			setPaginaAct(1)
		}
	}, [filtroBusqueda, fetchDeACuantos])

	useEffect(() => {
		if (!loading) {
			refreshConsultorias()
		}
	}, [paginaAct])

	const opcionesCuantosMostrar = [10, 25, 50]

	const handleAction = (consultoria, cual) => {
		setConsultoriaActual(consultoria, setQueMostrar(cual))
	}

	const cambiarEstado = async (id, estado) => {
		const { msg } = await api.post('a/consultorias/' + id + '/cambiar-estado', { estado })
		refreshConsultorias()
		setQueMostrar('')
		toast.success(msg)
	}

	const rechazarConsultoria = async (id) => {
		const { msg } = await api.post('a/consultorias/' + id + '/rechazar', { rechazar: true })
		refreshConsultorias()
		setQueMostrar('')
		toast.success(msg)
	}

	const deshacerRechazarConsultoria = async (id) => {
		const { msg } = await api.post('a/consultorias/' + id + '/rechazar', { rechazar: false })
		refreshConsultorias()
		setQueMostrar('')
		toast.success(msg)
	}

	// render
	return (
		<Container fluid id="consultorias" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col className="mt-2">
					<Row>
						<Col>
							<FiltrosFormWrapper>
								<Row className="vw-100">
									<Row>
										<Col md={4} lg={3}>
											<Form.Group className="mb-3" controlId="filtro-busqueda">
												<Form.Label>Búsqueda</Form.Label>
												<Form.Control
													type="text"
													size="sm"
													value={busqueda}
													onChange={(e) => setBusqueda(e.target.value)}
												/>
											</Form.Group>
										</Col>
									</Row>
								</Row>
							</FiltrosFormWrapper>
						</Col>
					</Row>
					{/*
						<Row id="searchbar" className="justify-content-between align-items-center">
							<Col sm={6} md={3} style={{ display: 'block' }}>
								<small>
									<span className="d-none d-sm-inline-block">Mostrar de a</span>
									<Form.Group className="mb-3" style={{ display: 'inline-block' }}>
										<Form.Select size="sm" onChange={(e) => setFetchDeACuantos(e.target.value)} className="mx-1">
											{opcionesCuantosMostrar.map((o) => {
												return (
													<option value={o} key={`mostrar-${o}`}>
														{o}
													</option>
												)
											})}
										</Form.Select>
									</Form.Group>
									<span className="ms-2">consultorías</span>
								</small>
							</Col>
							<Col md={5} lg={4}>
								<Form.Group controlId="txtBusqueda">
									<InputGroup>
										<Form.Control
											autoFocus
											value={busqueda}
											onChange={(e) => setBusqueda(e.target.value)}
											type="text"
											placeholder="Buscar"
											aria-label="Buscar"
										/>
										<InputGroup.Text><Form.Label className="mb-0"><i className="fa fa-search"></i></Form.Label></InputGroup.Text>
									</InputGroup>
								</Form.Group>
							</Col>
						</Row>
						*/}
				</Col>
			</Row>
			{/* Table */}
			<Row  className="mt-3">
				<Col>
					<small>
						<span className="d-none d-sm-inline-block">Mostrar de a</span>
						<Form.Group className="mb-1" style={{ display: 'inline-block' }}>
							<Form.Select size="sm" onChange={(e) => setFetchDeACuantos(e.target.value)} className="mx-1">
								{opcionesCuantosMostrar.map((o) => {
									return (
										<option value={o} key={`mostrar-${o}`}>
											{o}
										</option>
									)
								})}
							</Form.Select>
						</Form.Group>
						<span className="ms-2">consultorías</span>
					</small>
				</Col>
			</Row>
			<Row>
				<Col>
					<Table id="tabla-consultorias" bordered hover>
						<thead>
							<tr>
								<th>Id</th>
								<th>Título</th>
								<th>Nombre</th>
								<th>Correo</th>
								<th>Fecha</th>
								<th>Estado</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{loading ? (
								<tr>
									<td colSpan="7">
										<Spinner
											animation="border"
											size="lg"
											role="status"
											aria-hidden="true"
											variant="primary"
											className="my-3"
										/>
									</td>
								</tr>
							) : (
								consultorias.length === 0 ? (
									<tr>
										<td colSpan="7">
											<Alert variant="info">No se encontraron consultorías</Alert>
										</td>
									</tr>
								) : (
									consultorias.map((consultoria, index) => {
										let filaClass = ''
										if (consultoria.estado == 'no-aceptado') {
											filaClass = 'text-danger'
										} else if (consultoria.estado == 'aceptado') {
											filaClass = 'text-success'
										}

										return (
											<tr key={consultoria.id} className={filaClass}>
												<td>{consultoria.id}</td>
												<td>{consultoria.titulo}</td>
												{/*<td style={{ textAlign: 'center' }}><img src={aliado.multimedia ? process.env.REACT_APP_API_URL + 'uploads/' + aliado.multimedia.url : 'logo192.png'} alt={aliado.nombre} /></td>*/}
												<td>{consultoria.nombreCompleto}</td>
												<td>{consultoria.correo}</td>
												<td>{consultoria.fechaRecibido.substr(0, 10)}</td>
												<td>{CONSULTORIAS_ESTADOS_LABELS_MAP[consultoria.estado]}</td>
												<td>
													<Button
														onClick={() => handleAction(consultoria, 'ver')}
														className="me-2"
														variant="primary"
														title="Ver"
													>
														<i className="fa fa-eye"></i>
													</Button>
													<Button
														onClick={() => handleAction(consultoria, 'cambiar-estado')}
														className="me-2"
														variant="secondary"
														title="Cambiar estado"
													>
														<i className="fa fa-sliders"></i>
													</Button>
													{consultoria.estado !== 'aceptado' ? (
														consultoria.estado !== 'no-aceptado' ? (
															<Button
																onClick={() => handleAction(consultoria, 'rechazar')}
																className="me-2"
																variant="danger"
																title="Rechazar"
															>
																<i className="fa fa-times"></i>
															</Button>
														) : (
															<Button
																onClick={() => handleAction(consultoria, 'deshacer-rechazar')}
																className="me-2"
																variant="warning"
																title="Cancelar rechazo"
															>
																<i className="fa fa-history"></i>
															</Button>
														)
													) : null}
													{ }
												</td>
											</tr>
										)
									})
								)
							)}
						</tbody>
					</Table>
				</Col>
			</Row>
			<Row>
				<Col>
					<Paginacion
						pagina={paginaAct}
						numPaginas={Math.ceil(numResultados / fetchDeACuantos) || 1}
						setPagina={setPaginaAct}
					/>
				</Col>
			</Row>
			{/* Panel Ver */}
			{consultoriaActual && queMostrar === 'ver' &&
				<VerConsultoria
					consultoria={consultoriaActual}
					setQueMostrar={setQueMostrar}
				/>
			}
			{/* Panel Editar */}
			{consultoriaActual && queMostrar === 'cambiar-estado' &&
				<CambiarEstadoConsultoria
					consultoria={consultoriaActual}
					setQueMostrar={setQueMostrar}
					cambiarEstado={cambiarEstado}
				/>
			}
			{/* Modal rechazar */}
			{consultoriaActual && queMostrar === 'rechazar' && (
				<RechazarConsultoria
					consultoria={consultoriaActual}
					setQueMostrar={setQueMostrar}
					rechazar={rechazarConsultoria}
				/>
			)}
			{/* Modal rechazar */}
			{consultoriaActual && queMostrar === 'deshacer-rechazar' && (
				<DeshacerRechazarConsultoria
					consultoria={consultoriaActual}
					setQueMostrar={setQueMostrar}
					deshacerRechazar={deshacerRechazarConsultoria}
				/>
			)}
		</Container>
	)
}

export default Consultorias