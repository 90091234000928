import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { FormLabel, Offcanvas } from 'react-bootstrap'
import { PanelFooter, PanelHeader } from 'components/Panel'
import FileUpload from 'components/FileUpload/FileUpload'

function EditarSocio(props) {
	const { socioOriginal, guardar, setQueMostrar } = props

	const [datosSocio, setDatosSocio] = useState({})

	useEffect(() => {
		setDatosSocio({
			id: socioOriginal.id,
			nombre: socioOriginal.nombre,
			direccion: socioOriginal.direccion,
			telefono: socioOriginal.telefono,
			email: socioOriginal.email,
			web: socioOriginal.web,
			orden: socioOriginal.orden
		})

	}, [socioOriginal])

	const handleClose = () => {
		setQueMostrar('')
		setDatosSocio({})
	}

	const handleProcesar = (e) => {
		e.preventDefault()

		const data = {
			...datosSocio,
		}
		
		guardar(data)
		handleClose()
	}

	const handleChange = (value, key) => {
		const datosCopia = { ...datosSocio }
		datosCopia[key] = value
		setDatosSocio(prev => datosCopia)
	}

	const handleSeleccionImagen = (files) => {
		setDatosSocio({ ...datosSocio, imagen: files[0] })
	}

	// render
	return (
		<Offcanvas show={true} onHide={handleClose} placement="end" scroll="true">
			<PanelHeader onClick={handleClose} iconClass="fa-arrow-left" btnText="Atrás" text="Nuevo socio" />
			<Offcanvas.Body>
				<h1>Datos del socio</h1>
				{datosSocio.id && (
					<Row>
						<Col sm={12} md={6}>
							<Form onSubmit={handleProcesar} className="ms-4">
								<Row>
									<Col>
										<Form.Group className="mb-3" controlId="socio-nombre">
											<FormLabel>Nombre</FormLabel>
											<Form.Control
												value={datosSocio.nombre}
												type="text"
												onChange={(e) => handleChange(e.target.value, 'nombre')}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form.Group className="mb-3" controlId="socio-web">
											<FormLabel>Web</FormLabel>
											<Form.Control
												value={datosSocio.web}
												type="text"
												onChange={(e) => handleChange(e.target.value, 'web')}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form.Group className="mb-3" controlId="socio-email">
											<FormLabel>Email</FormLabel>
											<Form.Control
												value={datosSocio.email}
												type="text"
												onChange={(e) => handleChange(e.target.value, 'email')}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form.Group className="mb-3 col-md-3" controlId="socio-orden">
											<FormLabel>Orden</FormLabel>
											<Form.Control
												value={datosSocio.orden}
												type="number"
												onChange={(e) => handleChange(e.target.value, 'orden')}
											/>
										</Form.Group>
									</Col>
								</Row>
							</Form>
						</Col>
						<Col sm={12} md={6}>
							{socioOriginal.multimedia && (
								<div className="text-center logo-actual">
									<h4>Logo seleccionado</h4>
									<img src={process.env.REACT_APP_API_URL + 'uploads/' + socioOriginal.multimedia.url} alt="Logo"/>
									<hr className="mb-5" />
								</div>
							)}

							<FileUpload
								title="Cambiar logo"
								accept=".png, .jpg, .jpeg"
								buttonText="Elegir"
								buttonTextSingular="imagen"
								updateFiles={handleSeleccionImagen}
							/>
						</Col>
					</Row>
				)}
			</Offcanvas.Body>
			<PanelFooter>
				<Button variant="outline-tertiary" onClick={handleClose} className="me-2">
					Cancelar
				</Button>
				<Button variant="primary" onClick={handleProcesar}>
					Guardar
				</Button>
			</PanelFooter>
		</Offcanvas>
	)
}

export default EditarSocio