import { Avatar } from 'components/Avatar'
import React from 'react'

const ApoyoItem = ({ apoyo, onSelect }) => {

	const handleSelect = () => {
		onSelect(apoyo)
	}

	return (
		<div className="apoyo d-flex align-items-center" onClick={handleSelect}>
			<Avatar name={apoyo.nombre} url={apoyo.avatar ? process.env.REACT_APP_API_URL + apoyo.avatar : null} size={50} />
			<span style={{ flexGrow: 1, marginLeft: 10, textAlign: 'left' }}>{`${apoyo.nombre} ${apoyo.apellido}`}</span>
			<span className="me-2"><i className="fa fa-caret-right"></i></span>
		</div>
	)
}

export default ApoyoItem