import React from 'react'

import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav'
import '@trendmicro/react-sidenav/dist/react-sidenav.css'
import MenuSeparator from './MenuSeparator'

import { removeItem } from 'utils/localstorage'
import { LOCALSTORAGE_KEY } from 'utils/constants'
import Logo from 'assets/iso-logo.jpg'
import './SideMenu.css'

function SideMenu(props) {
	const { location, history, setLoggedIn, menuExpanded, setMenuExpanded } = props

	const onToggle = (isExpanded) => {
		setMenuExpanded(isExpanded)
	}

	const handleLogOut = () => {
		setLoggedIn(false)
		removeItem(LOCALSTORAGE_KEY)
	}

	let selected = location.pathname.substring(1)
	if (selected === 'login' || selected === '') {
		selected = 'inicio'
	}

	return (
		<SideNav expanded={menuExpanded} onToggle={onToggle} id="sidenav"
			onSelect={(selected) => {
				const to = '/' + selected
				if (location.pathname !== to) {
					history.push(to)
				}
			}}
		>
			<SideNav.Toggle />
			<div className="sidenavHeader">

			</div>
			<SideNav.Nav defaultSelected={selected}>
				<div className="sidenavLogo">
					<img src={Logo} alt="logo" />
				</div>
				<NavItem eventKey="inicio">
					<NavIcon>
						<i className="fa fa-fw fa-home" />
					</NavIcon>
					<NavText>
						Inicio
					</NavText>
				</NavItem>
				<NavItem eventKey="usuarios">
					<NavIcon>
						<i className="fas fa-users" />
					</NavIcon>
					<NavText>
						Usuarios <div className="submenu-nav"><i className="fa fa-caret-down expand"></i> <i className="fa fa-caret-up collapse"></i></div>
					</NavText>
					<NavItem eventKey="usuarios/administradores">
						<NavText title="Administradores">
							Administradores
						</NavText>
					</NavItem>
					<NavItem eventKey="usuarios/conectivo">
						<NavText title="Conectivo">
							Conectivo
						</NavText>
					</NavItem>
				</NavItem>
				<NavItem eventKey="socios">
					<NavIcon>
						<i className="far fa-id-card" />
					</NavIcon>
					<NavText>
						Socios
					</NavText>
				</NavItem>
				<NavItem eventKey="aliados">
					<NavIcon>
						<i className="fa-solid fa-globe" />
					</NavIcon>
					<NavText>
						Aliados
					</NavText>
				</NavItem>
				<NavItem eventKey="consultorias">
					<NavIcon>
						<i className="fa-solid fa-diagram-project" />
					</NavIcon>
					<NavText>
						Consultorías
					</NavText>
				</NavItem>
				<NavItem eventKey="sectores">
					<NavIcon>
						<i className="fa-solid fa-tag" />
					</NavIcon>
					<NavText>
						Sectores
					</NavText>
				</NavItem>
				<NavItem eventKey="demandas">
					<NavIcon>
						<i className="fas fa-bullhorn" />
					</NavIcon>
					<NavText>
						Demandas <div className="submenu-nav"><i className="fa fa-caret-down expand"></i> <i className="fa fa-caret-up collapse"></i></div>
					</NavText>
					<NavItem eventKey="demandas/pendientes">
						<NavText title="Pendientes">
							Pendientes
						</NavText>
					</NavItem>
					<NavItem eventKey="demandas/actuales">
						<NavText title="Actuales">
							Actuales
						</NavText>
					</NavItem>
					<NavItem eventKey="demandas/historial">
						<NavText title="Historial">
							Historial
						</NavText>
					</NavItem>
				</NavItem>
                <NavItem eventKey="ofertas">
					<NavIcon>
						<i className="fas fa-bookmark" />
					</NavIcon>
					<NavText>
						Ofertas <div className="submenu-nav"><i className="fa fa-caret-down expand"></i> <i className="fa fa-caret-up collapse"></i></div>
					</NavText>					
					<NavItem eventKey="ofertas/actuales">
						<NavText title="Actuales">
							Actuales
						</NavText>
					</NavItem>
					<NavItem eventKey="ofertas/historial">
						<NavText title="Historial">
							Historial
						</NavText>
					</NavItem>
				</NavItem>
				<NavItem eventKey="proyectos">
					<NavIcon>
						<i className="fas fa-lightbulb" />
					</NavIcon>
					<NavText>
						Proyectos <div className="submenu-nav"><i className="fa fa-caret-down expand"></i> <i className="fa fa-caret-up collapse"></i></div>
					</NavText>
					<NavItem eventKey="proyectos/pendientes">
						<NavText title="Pendientes">
							Pendientes
						</NavText>
					</NavItem>
					<NavItem eventKey="proyectos/actuales">
						<NavText title="Actuales">
							Actuales
						</NavText>
					</NavItem>
					<NavItem eventKey="proyectos/en-curso">
						<NavText title="En curso">
							En curso
						</NavText>
					</NavItem>
					<NavItem eventKey="proyectos/historial">
						<NavText title="Historial">
							Historial
						</NavText>
					</NavItem>
				</NavItem>
				<NavItem eventKey="estadisticas">
					<NavIcon>
						<i className="fa-solid fa-chart-simple" />
					</NavIcon>
					<NavText>
						Estadísticas
					</NavText>
				</NavItem>
				<MenuSeparator />
				<NavItem eventKey="seccion2">
					<NavIcon>
						<i className="fas fa-cog" />
					</NavIcon>
					<NavText>
						Contenido landing <div className="submenu-nav"><i className="fa fa-caret-down expand"></i> <i className="fa fa-caret-up collapse"></i></div>
					</NavText>
					<NavItem eventKey="modificaciones/inicio">
						<NavText title="Inicio">
							Inicio
						</NavText>
					</NavItem>
					<NavItem eventKey="modificaciones/quienes-somos">
						<NavText title="Quiénes somos">
							Quiénes somos
						</NavText>
					</NavItem>
					<NavItem eventKey="modificaciones/ejes">
						<NavText title="Ejes">
							Ejes
						</NavText>
					</NavItem>
					<NavItem eventKey="modificaciones/conecta">
						<NavText title="Conectá">
							Conectá
						</NavText>
					</NavItem>
					<NavItem eventKey="modificaciones/contacto">
						<NavText title="Contacto">
							Contacto
						</NavText>
					</NavItem>
				</NavItem>
				<MenuSeparator />
				<NavItem eventKey="modificaciones-conectivo">
					<NavIcon>
						<i className="fas fa-cog" />
					</NavIcon>
					<NavText>
						Contenido Conectivo<div className="submenu-nav"><i className="fa fa-caret-down expand"></i> <i className="fa fa-caret-up collapse"></i></div>
					</NavText>
					<NavItem eventKey="modificaciones/conectivo-inicio">
						<NavText title="Inicio">
							Inicio
						</NavText>
					</NavItem>
					<NavItem eventKey="modificaciones/conectivo-beneficios">
						<NavText title="Beneficios">
							Beneficios
						</NavText>
					</NavItem>
					<NavItem eventKey="modificaciones/conectivo-como-funciona">
						<NavText title="Cómo funciona">
							Cómo funciona
						</NavText>
					</NavItem>
				</NavItem>
				<MenuSeparator />
				<NavItem eventKey="logout" onClick={handleLogOut}>
					<NavIcon>
						<i className="fa fa-fw fa-power-off" style={{ fontSize: '1.5em' }} />
					</NavIcon>
					<NavText style={{ paddingRight: 32 }} title="Cerrar">
						Cerrar
					</NavText>
				</NavItem>
			</SideNav.Nav>
		</SideNav>
	)
}

export default SideMenu