import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Image from 'react-bootstrap/Image'
import { agregarEspaciosTel, extraerTel } from 'utils/telefono'
import { formatDate, formatTime } from 'utils/date'
import { Avatar } from 'components/Avatar'
import { Rating } from 'react-simple-star-rating'

const ContactoPopup = (props) => {
	const { contacto, onClose } = props

	const handleClose = () => {
		onClose()
	}

	const getSectores = (sectores) => {

		return (
			<div className="demanda-lista-sectores">
				{sectores.map(sector => (
					<span key={`sector-${sector.id}`} className="demanda-sector">{sector.nombre}</span>
				))}
			</div>
		)
	}

	const contactoDate = new Date(contacto.fechaContacto)

	return (
		<Modal show={true} size="md" onHide={handleClose} className="contacto-modal">
			<Modal.Header closeButton>
				<Modal.Title><h2 className="sin-subrayado">Contacto</h2></Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="d-flex gap-3 align-items-center">
					<div className="d-flex flex-column align-items-center">
						<Avatar name={contacto.nombre} url={contacto.avatar ? process.env.REACT_APP_API_URL + contacto.avatar : null} size={100} />
						<Rating
							initialValue={contacto.calificacion.oferente}
							allowTitleTag={false}
							readonly={true}
							allowFraction={true}
							size={30}
							className="mt-1"
						/>
					</div>
					<div className="detalles-contacto d-flex flex-column">
						<div>
							{`${contacto.nombre} ${contacto.apellido}`}
						</div>
						<div>
							<a href={`mailto:${contacto.usuario}`}>{contacto.usuario}</a>
						</div>
						<div>
							<a href={`tel:${extraerTel(contacto.telefono)}`}>{agregarEspaciosTel(contacto.telefono)}</a>
						</div>
						<div>
							{getSectores(contacto.areas)}
						</div>
					</div>
				</div>
				<small className="text-muted">Fecha de contacto: {`${formatDate(contactoDate)}, a las ${formatTime(contactoDate)}`}</small>
				<div className="fs-4 fw-bold mt-3">Experiencia</div>
				<div>
					{contacto.experiencia}
				</div>
				<div className="fs-4 fw-bold mt-3">Portafolio</div>
				{contacto.portafolioUrl && (
					<div>
						<a href={contacto.portafolioUrl} target="_blank">Ver pdf</a>
					</div>
				)}

			</Modal.Body>
		</Modal>
	)
}

export default ContactoPopup