import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'


function SuspenderUsuario(props) {
	const { usuario, cancelar, mostrar, suspender } = props

	const handleClose = () => {
		cancelar()
	}

	useEffect(() => {

	}, [usuario])

	return (
		<Modal show={mostrar} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>{usuario?.suspendido ? 'Levantar suspensión' : 'Suspender'} usuario</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{usuario?.suspendido ? (
					<>
						<Alert variant="info">
							Está a punto de levantar la suspensión al usuario <strong>{`${usuario?.nombre} ${usuario?.apellido}`}</strong>.
						</Alert>
						<p>¿Está seguro que desea continuar?</p>
					</>
				) : (
					<>
						<Alert variant="warning">
							Está a punto de suspender al usuario <strong>{`${usuario?.nombre} ${usuario?.apellido}`}</strong>.
						</Alert>
						<p>¿Está seguro que desea continuar?</p>
					</>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={handleClose}>
					Cancelar
				</Button>
				<Button variant={usuario?.suspendido ? 'primary' : 'warning'} onClick={() => suspender(usuario?.id, !usuario?.suspendido)}>
					{usuario?.suspendido ? 'Levantar suspensión' : 'Suspender'}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default SuspenderUsuario