import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import DropdownButton from 'react-bootstrap/DropdownButton'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import { LoadingSpinner } from 'components/LoadingSpinner'
import Paginacion from 'components/Paginacion'

export default function ListaOfertas(props) {
	const {
		loading,
		ofertas,
		numResultados,
		fetchDeACuantos,
		pagina,
		cambiarPagina,
		acciones
	} = props

	return (
		<div className="lista-ofertas">
			<Row>
				<Col>
					<small>Total resultados: {loading ? (<span className="ms-2"><LoadingSpinner size='sm' /></span>) : (<strong>{numResultados}</strong>)}</small>
				</Col>
			</Row>
			{/* TABLE */}
			<Row>
				<Col>
					<Table id="tabla-ofertas" striped bordered hover>
						<thead>
							<tr>
								<th>id</th>
								<th>Usuario</th>
								<th>Nombre</th>
								<th>Fecha creación</th>
								<th># visualizaciones</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{
								loading ? (
									<tr>
										<td colSpan="7">
											<LoadingSpinner />
										</td>
									</tr>
								) : (
									ofertas.length ? (
										ofertas.map((oferta, i) => {
											return (
												<tr key={`oferta-${oferta.id}`}>
													<td>{oferta.id}</td>
													<td>{oferta.usuario.nombre}</td>
													<td>{oferta.nombre}</td>
													<td>{oferta.fechaCreacion?.substr(0, 10) || '-'}</td>
													<td>{oferta.visualizaciones.length}</td>
													<td className="text-center">
														<DropdownButton
															as={ButtonGroup}
															title="OPCIONES"
															id="bg-nested-dropdown"
															className="fst-normal"
														>
															{acciones.handleVer && (<Dropdown.Item
																onClick={() => acciones.handleVer(oferta.id)}
																eventKey="ver"
															>
																Ver
															</Dropdown.Item>)}
															{acciones.handleAprobar && (<Dropdown.Item
																onClick={() => acciones.handleAprobar(oferta.id)}
																eventKey="aprobar"
															>
																Aprobar
															</Dropdown.Item>)}
															{acciones.handleComentar && (<Dropdown.Item
																onClick={() => acciones.handleComentar(oferta.id)}
																eventKey="comentar"
															>
																Comentar
															</Dropdown.Item>)}
															{acciones.handleFinalizar && (<Dropdown.Item
																onClick={() => acciones.handleFinalizar(oferta.id)}
																eventKey="finalizar"
															>
																Finalizar
															</Dropdown.Item>)}
															{acciones.handleDesmarcarFinalizar && (<Dropdown.Item
																onClick={() => acciones.handleDesmarcarFinalizar(oferta.id)}
																eventKey="desmarcarFinalizar"
															>
																No finalizado
															</Dropdown.Item>)}
														</DropdownButton>
													</td>
												</tr>
											)
										})
									) : (
										<tr>
											<td colSpan="7">
												No se encontraron ofertas.
											</td>
										</tr>
									)
								)
							}
						</tbody>
					</Table>
				</Col>
			</Row>
			
			<Row>
				<Col>
					<Paginacion
						pagina={pagina}
						numPaginas={Math.ceil(numResultados / fetchDeACuantos) || 1}
						setPagina={cambiarPagina}
					/>
				</Col>
			</Row>
						
		</div>
	)
}