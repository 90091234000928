import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { PasswordInput } from 'components/PasswordInput'
import { toast } from 'react-toastify'
import api from 'api/api'


const CambiarContrasenaPopup = (props) => {
	const { mostrar, usuario, onClose, onSuccess } = props
	const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [contrasena, setContrasena] = useState('')    

    const handleChange = (value) => {
        setContrasena(value)
        if (value && value.length < 8) {
            setErrors({ contrasena: 'La contraseña debe tener al menos 8 caracteres' })
        } else if (value) {
            setErrors({ contrasena: null })
        }
    }
	const guardarContrasena = async (e) => {
        e.preventDefault()
        setLoading(true)
		try {
            const { msg, datos: respuesta } = await api.post('a/usuarios/' + usuario.id + '/cambiar-contrasena', {contrasena: contrasena})
            console.log(respuesta)
            onSuccess()
            handleClose()
            toast.success(msg)
        } catch (error) {
            console.error(error)
            toast.error('Error de conexión')           
        }
        setLoading(false)
	}

	const handleClose = () => {
        setContrasena(null)
        setErrors({})
		onClose()
	}

	return (
		<Modal show={mostrar} size="md" onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title><h2 className="sin-subrayado">Cambio de contraseña</h2></Modal.Title>
			</Modal.Header>
			<Modal.Body>
                { usuario ? <Alert variant="warning">Al presionar Guardar se confirma el cambio de contraseña del usuario conectivo <strong>{usuario.nombre} {usuario.apellido}</strong>, con email <strong>{usuario.email}</strong>.</Alert> : null }
                <Form onSubmit={guardarContrasena}>
                    <PasswordInput
                        className="mb-3"
                        label="Contraseña nueva"
                        controlId="contrasena"
                        placeholder="Ingresa nueva contraseña"
                        value={contrasena}
                        onChange={(e) => handleChange(e.target.value)}
                        isInvalid={!!errors.contrasena}
                        invalidFeedback={errors.contrasena}
                        showLock
                    />

                    <Row>
                        <Col md={6} className="text-center">
                            <Button style={{width: '100%'}} className="pt-1 mb-4" type="button" variant="secondary" size="lg" disabled={loading} onClick={handleClose}>Cancelar</Button>
                        </Col>
                        <Col md={6} className="text-center">
                            <Button style={{width: '100%'}} className="pt-1 mb-4" type="submit" variant="primary" size="lg" disabled={loading || !contrasena || errors.contrasena}>
                                {
                                loading
                                ? (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )
                                : 'Guardar'
                                }
                            </Button>
                        </Col>
                    </Row>
                </Form>
			</Modal.Body>
		</Modal>
	)
}

export default CambiarContrasenaPopup