import React, { useState, useEffect, useCallback } from 'react'
import api from 'api/api'
import axios from 'axios'
import { DemandasConfigContext } from './DemandasConfigContext'

const DemandasConfigProvider = ({ children }) => {
	const [data, setData] = useState({
		areas: [],
	})
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)

	const fetchData = useCallback(async () => {
		setLoading(true)
		const cancelToken = axios.CancelToken.source()

		try {
			const { datos: respuesta } = await api.get('a/config-demandas', {
				cancelToken: cancelToken.token,
			})
			setData(respuesta)
			setError(null)
		} catch (err) {
			if (axios.isCancel(err)) {
				setError('Request canceled')
			} else if (err.code === 'ECONNABORTED') {
				setError('Request timed out')
			} else {
				setError(err.message)
			}
		} finally {
			setLoading(false)
		}

		// Devuelve una función que cancela la petición
		return cancelToken.cancel
	}, [])

	useEffect(() => {
		let cancelFetch

		// Define una función asíncrona que también maneja la cancelación
		const fetch = async () => {
			cancelFetch = await fetchData()
		}

		// Llama a la función asíncrona definida
		fetch()

		// La función de limpieza ahora llama a la función de cancelación guardada
		return () => {
			if (cancelFetch) cancelFetch() // Llama a la función de cancelación
		}
	}, [fetchData])

	const refresh = () => {
		fetchData()
	}

	return (
		<DemandasConfigContext.Provider value={{ data, loading, error, refresh }}>
			{children}
		</DemandasConfigContext.Provider>
	)
}

export { DemandasConfigContext, DemandasConfigProvider }
