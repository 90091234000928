import { useState, useEffect, useCallback } from 'react'
import { DEFAULT_FETCH_CUANTOS } from 'config/proyectos'
import api from 'api/api'
import axios from 'axios'

export default function useProyectosFiltrados(filtrosIniciales) {
	const [loading, setLoading] = useState(false)
	const [proyectos, setProyectos] = useState([])
	const [error, setError] = useState(null)
	const [pagina, setPagina] = useState(1)
	const [numResultados, setNumResultados] = useState(0)
	const [fetchDeACuantos, setFetchDeACuantos] = useState(DEFAULT_FETCH_CUANTOS)
	const [filtros, setFiltros] = useState({ ...filtrosIniciales })

	const fetchProyectos = useCallback(async () => {
		setLoading(true)
		const cancelToken = axios.CancelToken.source()
		const filtrosServer = { ...filtros }
		if (filtros.areaId) {
			if (filtros.areaId.length === 0) {
				delete filtrosServer['areaId']
			} else {
				filtrosServer.areaId = ['in', filtros.areaId]
			}	
		} else {
			delete filtrosServer['areaId']
		}

		const body = {
			desde: pagina === 1 ? 0 : (pagina - 1) * fetchDeACuantos,
			cuantos: fetchDeACuantos,
			filtros: filtrosServer,
		}

		try {
			const { datos: respuesta } = await api.post('a/ver-proyectos', body, {
				cancelToken: cancelToken.token,
			})

			if (!respuesta.error) {
				setProyectos(respuesta.proyectos)
				setNumResultados(respuesta.numResultados)
				setError(null)
			} else {
				setError(respuesta.msg)
			}
		} catch (err) {
			if (axios.isCancel(err)) {
				setError('Petición cancelada')
			} else {
				setError(err.message)
			}
		} finally {
			setLoading(false)
		}
	}, [pagina, fetchDeACuantos, filtros])

	useEffect(() => {
		fetchProyectos()
	}, [fetchProyectos])

	const cambiarFiltro = (valor, cual) => {
		setFiltros(prev => ({ ...prev, [cual]: valor }))
	}

	const cambiarPagina = (num) => {
		setPagina(num)
	}

	const resetData = () => {
		setProyectos([])
		setFiltros({ ...filtrosIniciales })
		setPagina(1)
		setNumResultados(0)
		setFetchDeACuantos(DEFAULT_FETCH_CUANTOS)
		setLoading(false)
		setError(null)
	}

	return {
		proyectos,
		loading,
		error,
		cambiarFiltro,
		cambiarPagina,
		resetData,
		numResultados,
		pagina,
		fetchDeACuantos,
		setFetchDeACuantos,
		filtros,
	}
}
