import { Avatar } from 'components/Avatar'
import React from 'react'

const ContactoItem = ({ contacto, onSelect }) => {

	const handleSelect = () => {
		onSelect(contacto)
	}

	return (
		<div className="contacto d-flex align-items-center" onClick={handleSelect}>
			<Avatar name={contacto.nombre} url={contacto.avatar ? process.env.REACT_APP_API_URL + contacto.avatar : null} size={50} />
			<span style={{ flexGrow: 1, marginLeft: 10 }}>{`${contacto.nombre} ${contacto.apellido}`}</span>
			<span className="me-2"><i className="fa fa-caret-right"></i></span>
		</div>
	)
}

export default ContactoItem