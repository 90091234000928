import React, { useContext, useEffect, useState, useRef } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { Tabs, Tab } from 'react-bootstrap'
import { ProyectosConfigContext } from 'contexts/ProyectosConfigContext'
import useProyectosFiltrados from 'hooks/useProyectosFiltrados'
import ListaProyectos from '../ListaProyectos'
import VerProyecto from '../VerProyecto'
import FinalizarProyecto from '../FinalizarProyecto'
import { FiltrosFormWrapper } from 'components/FiltrosFormWrapper'
import { MultiSelectCustom } from 'components/MultiSelectCustom'
import { debounce } from 'utils/debounce'
import { toast } from 'react-toastify'
import AsyncSelect from 'react-select/async'

import api from 'api/api'
import TabListado from './TabListado'
import TabCalendario from './TabCalendario'

const ProyectosEnCurso = () => {
	const { data: config } = useContext(ProyectosConfigContext)
	const [filtrosIniciales, setFiltrosIniciales] = useState({ estado: 'en-curso', finalizado: false, busqueda: '' })
	const { loading, proyectos, numResultados, cambiarPagina, filtros, cambiarFiltro, pagina, fetchDeACuantos, setFetchDeACuantos } = useProyectosFiltrados({ ...filtrosIniciales })
	const areasOptions = config.areas.map((e) => ({
		value: e.id,
		label: e.nombre,
	}))
	const [areas, setAreas] = useState(areasOptions)
	const [inicializado, setInicializado] = useState(false)
	const [busqueda, setBusqueda] = useState('')
	const busquedaAnteriorRef = useRef(busqueda)
	const [conApoyos, setConApoyos] = useState(false)
	const [key, setKey] = useState('listado')
	const [accionActual, setAccionActual] = useState({
		proyectoId: null,
		accion: ''
	})

	const handleChangeConApoyos = (checked) => {
		cambiarFiltro(checked, 'conApoyos')
		setConApoyos(checked)
	}

	const loadOptions = async (inputValue, callback) => {
		const { datos: respuesta } = await api.post('a/buscar-usuarios', { nombreCompleto: inputValue })
		callback(respuesta)
	}

	const onChangeBusquedaUsuario = (selected) => {
		if (selected) {
			cambiarFiltro(selected.value, 'usuarioId')
		} else {
			cambiarFiltro('', 'usuarioId')
		}
	}

	useEffect(() => {
		if (inicializado) {
			const areasIds = areas.map(e => e.value)
			cambiarFiltro(areasIds, 'areaId')
		} else {
			setInicializado(true)
		}
	}, [areas])

	const debouncedSetFiltroBusqueda = useRef(
		debounce((str) => {
			if (str !== busquedaAnteriorRef.current) {
				cambiarFiltro(str, 'busqueda')
			}
			busquedaAnteriorRef.current = str
		}, 400)
	).current

	useEffect(() => {
		debouncedSetFiltroBusqueda(busqueda)
	}, [busqueda])

	// Las acciones específicas para proyectos pendientes
	const accionesActuales = {
		handleVer: (proyectoId) => {
			setAccionActual({
				proyectoId,
				accion: 'ver'
			})
		},
		handleFinalizar: (proyectoId) => {
			setAccionActual({
				proyectoId,
				accion: 'finalizar'
			})
		},
	}

	const cerrarAccion = () => {
		setAccionActual({
			proyectoId: null,
			accion: ''
		})
	}

	const finalizar = async (id) => {
		const { msg } = await api.post('a/proyectos/' + id + '/finalizar', { finalizar: true })
		cambiarFiltro(filtros.busqueda, 'busqueda') // para que haga un refresh
		cerrarAccion()
		toast.success(msg)
	}

	return (
		<Container fluid id="proyectos-pendientes" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col xs={12} sm={6} lg={4} className="mt-2">
					<h1>Proyectos en curso</h1>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col>
					<FiltrosFormWrapper>
						<Row className="vw-100">
							<Row>
								<Col md={4} lg={3}>
									<Form.Group className="mb-3" controlId="filtro-areasIds">
										<Form.Label>Áreas</Form.Label>
										<MultiSelectCustom
											options={areasOptions}
											value={areas}
											onChange={(e) => setAreas(e)}
										/>
									</Form.Group>
								</Col>
								<Col md={3} lg={2}>
									<Form.Group className="mb-3" controlId="filtro-busqueda">
										<Form.Label>Búsqueda</Form.Label>
										<Form.Control
											type="text"
											value={busqueda}
											onChange={(e) => setBusqueda(e.target.value)}
										/>
									</Form.Group>
								</Col>
								<Col md={4} lg={3}>
									<Form.Group className="mb-3" controlId="filtro-busqueda">
										<Form.Label>Usuario</Form.Label>
										<AsyncSelect
											cacheOptions
											loadOptions={loadOptions}
											defaultOptions
											onChange={onChangeBusquedaUsuario}
											isClearable={true}
											placeholder={'Buscar...'}
										/>
									</Form.Group>
								</Col>
								<Col md={3} lg={2} className="d-flex align-items-center">
									<Form.Check
										inline
										id="filtro-apoyos"
										label="Con apoyos"
										type="checkbox"
										checked={conApoyos}
										onChange={(e) => handleChangeConApoyos(e.target.checked)}
										style={{ marginTop: 15 }}
									/>
								</Col>
							</Row>
						</Row>
					</FiltrosFormWrapper>
				</Col>
			</Row>
			<Tabs
				id="en-curso-tabs"
				activeKey={key}
				onSelect={(k) => setKey(k)}
				className="mb-3"
			>
				<Tab eventKey="listado" title="Listado">
					<TabListado 
						loading={loading}
						proyectos={proyectos}
						numResultados={numResultados}
						pagina={pagina}
						fetchDeACuantos={fetchDeACuantos}
						cambiarPagina={cambiarPagina}
						acciones={accionesActuales}
						accionActual={accionActual}
						cerrarAccion={cerrarAccion}
						finalizar={finalizar}
					/>
				</Tab>
				<Tab eventKey="calendario" title="Calendario">
					<TabCalendario
						loading={loading}
						proyectos={proyectos}
					/>
				</Tab>
			</Tabs>
		</Container>
	)
}

export default ProyectosEnCurso