import React from 'react';
import { COLOR_TAG_ROL } from 'config/colores-tag'

const TagRol = (props) => {
  return (
    <span
        style={{
            backgroundColor: COLOR_TAG_ROL,
            color: '#fff',
            padding: '3px 10px',
            borderRadius: '15px',
            fontSize: '14px',
            fontWeight: 'bold',
            marginRight: '8px',
            display: 'flex', 
            alignItems: 'center',
            width: 'auto'
        }}
    >
        <i class="fa-solid fa-user-check"></i>&nbsp;<span>{props.rol}</span>
    </span>
  );
};

export default TagRol;