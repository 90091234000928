import React from "react";
import { createRoot } from "react-dom/client"; // Importa createRoot desde react-dom/client

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-datetime/css/react-datetime.css";
import './custom-theme.css';
import "./index.css";

// Usa createRoot en lugar de ReactDOM.render
const root = document.getElementById("root");
const rootElement = createRoot(root);
rootElement.render(<App />);

// Resto del código...
reportWebVitals();
