import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import { PanelFooter, PanelHeader } from 'components/Panel'

import './aliados.css'
import { Offcanvas } from 'react-bootstrap'

function VerAliado(props) {
	const { aliado, setQueMostrar } = props

	const handleClose = () => {
		setQueMostrar('')
	}

	// render
	return (
		<Offcanvas show={true} onHide={handleClose} placement="end" scroll="true">
			<PanelHeader onClick={handleClose} iconClass="fa-arrow-left" btnText="Atrás" text="Ver aliado" />
			<Offcanvas.Body>
				<h1>Datos del aliado</h1>
				<Row>
					<Col sm={12} md={6}>
						<Table>
							<thead></thead>
							<tbody>
								<tr>
									<th>Id</th>
									<td>{aliado.id}</td>
								</tr>
								<tr>
									<th>Nombre</th>
									<td>{aliado.nombre}</td>
								</tr>
								<tr>
									<th>Web</th>
									<td>{aliado.web || '-'}</td>
								</tr>
								<tr>
									<th>Email</th>
									<td>{aliado.email || '-'}</td>
								</tr>
								<tr>
									<th>Tipo</th>
									<td>{aliado.tipo || '-'}</td>
								</tr>
								<tr>
									<th>Orden</th>
									<td>{aliado.orden || '-'}</td>
								</tr>
							</tbody>
						</Table>
					</Col>
					<Col sm={12} md={6} className="text-center">
						<h3>Logo</h3>
						<div className="logo-img">
							<img src={aliado.multimedia ? process.env.REACT_APP_API_URL + 'uploads/' + aliado.multimedia.url : 'logo192.png'} alt={aliado.nombre}/>
						</div>
					</Col>
				</Row>
			</Offcanvas.Body>
			<PanelFooter>
				<Button variant="primary" onClick={handleClose}>
					Ok
				</Button>
			</PanelFooter>
		</Offcanvas>
	)
}

export default VerAliado