import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { toast } from 'react-toastify'
import api from 'api/api'

import './usuarios.css'

import { DEFAULT_FETCH_CUANTOS, ROLES_USUARIO } from 'utils/constants'
import { debounce } from 'utils/debounce'
import ListaUsuarios from './ListaUsuarios'
import NuevoUsuario from './NuevoUsuario'
import EditarUsuario from './EditarUsuario'
import VerUsuario from './VerUsuario'
import SuspenderUsuario from './SuspenderUsuario'
import { capitalizeWord } from 'utils/general'
import { MultiSelectCustom } from 'components/MultiSelectCustom'
import { FiltrosFormWrapper } from 'components/FiltrosFormWrapper'
import CambiarContrasenaPopup from './CambiarContrasenaPopup'


function Usuarios(props) {
	const [fetchDeACuantos, setFetchDeACuantos] = useState(DEFAULT_FETCH_CUANTOS)
	const [numResultados, setNumResultados] = useState(0)
	const [paginaAct, setPaginaAct] = useState(1)
	const [loading, setLoading] = useState(false)

	const [busqueda, setBusqueda] = useState('')
	const [filtroBusqueda, setFiltroBusqueda] = useState('')
	const [usuarios, setUsuarios] = useState([])
	const [tipos, setTipos] = useState([])
	const [roles, setRoles] = useState(ROLES_USUARIO)
	const [areas, setAreas] = useState([])
	const [selectedAreas, setSelectedAreas] = useState([])
	const [selectedRol, setSelectedRol] = useState('')
	const [accionActual, setAccionActual] = useState({
		usuario: null,
		accion: ''
	})

	const areasOptions = areas.map((e) => ({
		value: e.id,
		label: e.nombre,
	}))

	const opcionesCuantosMostrar = [10, 25, 50]

	const refreshConfig = async () => {
		try {
			const { datos: respuesta } = await api.get('a/config-usuarios')
			setTipos(respuesta.cuentasTipos)
			setRoles(respuesta.roles)
			setAreas(respuesta.areas)
		} catch (error) {
			toast.error('Error ')
		}
	}

	useEffect(() => {
		refreshUsuarios()
		setPaginaAct(1)
	}, [selectedAreas])

	const refreshUsuarios = async () => {
		try {
			setLoading(true)
			const filtros = {
				usuario: [filtroBusqueda],
				nombre: [filtroBusqueda]
			}

			if (selectedAreas.length) {
				filtros.areasIds = selectedAreas.map(a => a.value)
			}

			if (selectedRol) {
				filtros.roles = [selectedRol]
			}

			const { datos } = await api.post('a/ver-usuarios', {
				cuantos: fetchDeACuantos,
				desde: paginaAct === 1 ? 0 : (paginaAct - 1) * fetchDeACuantos,
				filtros
			})

			setUsuarios(datos.usuarios)
			setNumResultados(datos.numResultados)
		} catch (error) {
			toast.error('Error de conexión')
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const handleBusqueda = (value) => {
		if (value) {
			setFiltroBusqueda(value)
		} else {
			setFiltroBusqueda('')
		}
	}

	const debouceFiltroBusqueda = React.useCallback(
		debounce(handleBusqueda, 400),
		[]
	)

	useEffect(() => {
		debouceFiltroBusqueda(busqueda)
	}, [busqueda])

	useEffect(() => {
		if (paginaAct === 1) {
			refreshUsuarios()
		} else {
			setPaginaAct(1)
		}
	}, [filtroBusqueda, fetchDeACuantos, selectedRol, selectedAreas])

	useEffect(() => {
		if (!loading) {
			refreshUsuarios()
		}
	}, [paginaAct])

	const nuevoUsuario = async (data) => {
		try {
			const { msg } = await api.post('a/usuarios', data)
			refreshConfig()
			refreshUsuarios()
			cerrarAccion()
			toast.success(msg)
		} catch (error) {
			console.error(error)
			toast.error('Error de conexión')
		}
	}

	const editarUsuario = async (data) => {
		if (Object.keys(data).length) {
			const enviar = {
				...data,
				usuarioId: accionActual.usuario.id
			}

			try {
				const { msg } = await api.put('a/usuarios/' + accionActual.usuario.id, enviar)
				refreshConfig()
				refreshUsuarios()
				cerrarAccion()
				toast.success(msg)
			} catch (error) {
				console.error(error)
				toast.error('Error de conexión')
			}
		}
	}

	const suspenderUsuario = async (id, suspender) => {
		const { msg } = await api.post('a/usuarios/' + id + '/suspender', { suspender })
		refreshConfig()
		refreshUsuarios()
		cerrarAccion()
		toast.success(msg)
	}

	useEffect(() => {
		if (!loading) {
			refreshConfig()
			refreshUsuarios()
		}
	}, [])

	// Las acciones específicas para la lista de usuarios
	const accionesActuales = {
		handleVer: (usuario) => {
			setAccionActual({
				usuario,
				accion: 'ver'
			})
		},
        handleCambiarContrasena: (usuario) => {
			setAccionActual({
				usuario,
				accion: 'cambiar-contrasena'
			})
		},
		handleSuspender: (usuario) => {
			setAccionActual({
				usuario,
				accion: 'suspender'
			})
		},
	}

	const cerrarAccion = () => {
		setAccionActual({
			usuario: {},
			accion: ''
		})
	}

    const onCambiarContrasena = () => {
        cerrarAccion()
        refreshUsuarios()
    }

	// render
	return (
		<Container fluid id="usuarios" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col xs={12} className="mt-2">
					<h1>Usuarios</h1>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col>
					<FiltrosFormWrapper>
						<Row className="vw-100">
							<Row>
								<Col md={4} lg={3}>
									<Form.Group className="mb-3" controlId="filtro-busqueda">
										<Form.Label>Búsqueda</Form.Label>
										<Form.Control
											type="text"
											size="sm"
											value={busqueda}
											onChange={(e) => setBusqueda(e.target.value)}
										/>
									</Form.Group>
								</Col>
								<Col sm={2}>
									<Form.Group className="mb-3" controlId="filtro-rol">
										<Form.Label>Rol</Form.Label>
										<Form.Select value={selectedRol} onChange={(e) => setSelectedRol(e.target.value)} size="sm">
											<option value={''} key={`rol-todos`}>
												Todos
											</option>
											{roles.map((rol) => {
												return (
													<option value={rol} key={`rol-${rol}`}>
														{capitalizeWord(rol)}
													</option>
												)
											})}
										</Form.Select>
									</Form.Group>
								</Col>
								{(selectedRol === '' || selectedRol === 'oferente') && (
									<Col md={4} lg={3}>
										<Form.Group className="mb-3" controlId="filtro-areasIds">
											<Form.Label>Áreas</Form.Label>
											<MultiSelectCustom
												options={areasOptions}
												value={selectedAreas}
												onChange={(e) => setSelectedAreas(e)}
												size="sm"
											/>
										</Form.Group>
									</Col>
								)}
							</Row>
						</Row>
					</FiltrosFormWrapper>
				</Col>
			</Row>
			<Row className="d-flex no-gutters">
				<Col className="mt-2">
					<Form>
						<Row id="searchbar" className="justify-content-between align-items-center">
							<Col sm={6} md={3} style={{ display: 'block' }}>
								<small>
									<span className="d-none d-sm-inline-block">Mostrar de a</span>
									<Form.Group style={{ display: 'inline-block' }}>
										<Form.Select size="sm" onChange={(e) => setFetchDeACuantos(e.target.value)} className="mx-1">
											{opcionesCuantosMostrar.map((o) => {
												return (
													<option value={o} key={`mostrar-${o}`}>
														{o}
													</option>
												)
											})}
										</Form.Select>
									</Form.Group>
									<span className="ms-2">usuarios</span>
								</small>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
			{/* Lista usuarios */}
			<ListaUsuarios
				loading={loading}
				usuarios={usuarios}
				numResultados={numResultados}
				pagina={paginaAct}
				fetchDeACuantos={fetchDeACuantos}
				cambiarPagina={setPaginaAct}
				acciones={accionesActuales}
			/>
			{/* Panel Nuevo */}
			<NuevoUsuario
				mostrar={accionActual.accion === 'nuevo'}
				guardar={nuevoUsuario}
				cancelar={cerrarAccion}
				tiposDisponibles={tipos}
			/>
			{/* Panel Ver */}
			<VerUsuario
				mostrar={accionActual.accion === 'ver'}
				usuario={accionActual.usuario}
				cancelar={cerrarAccion}
				tiposDisponibles={tipos}
			/>
            {/* Panel Cambiar contraseña */}
			<CambiarContrasenaPopup
				mostrar={accionActual.accion === 'cambiar-contrasena'}
				usuario={accionActual.usuario}
				onClose={cerrarAccion}
				onSuccess={onCambiarContrasena}
			/>
			{/* Panel Editar */}
			<EditarUsuario
				mostrar={accionActual.accion === 'editar'}
				usuarioOriginal={accionActual.usuario}
				cancelar={cerrarAccion}
				guardar={editarUsuario}
				tiposDisponibles={tipos}
			/>
			{/* Modal Delete */}
			<SuspenderUsuario
				mostrar={accionActual.accion === 'suspender'}
				usuario={accionActual.usuario}
				cancelar={cerrarAccion}
				suspender={suspenderUsuario}
			/>
		</Container>
	);
}

export default Usuarios