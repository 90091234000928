import React, { useState, useRef, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'

import { toast } from 'react-toastify'

import api from 'api/api'
import './sectores.css'
import { debounce } from 'utils/debounce'
import Paginacion from 'components/Paginacion'
import NuevoSector from './NuevoSector'
import DeleteSector from './DeleteSector'
import VerSector from './VerSector'
import EditarSector from './EditarSector'
import Thumb from 'components/Thumb/Thumb'


function Sectores() {
	const [fetchDeACuantos, setFetchDeACuantos] = useState(10)
	const [numResultados, setNumResultados] = useState(0)
	const [paginaAct, setPaginaAct] = useState(1)
	const [loading, setLoading] = useState(false)

	const [busqueda, setBusqueda] = useState('')
	const [filtroBusqueda, setFiltroBusqueda] = useState('')
	const [sectores, setSectores] = useState([])
	const [sectorActual, setSectorActual] = useState()
	const [queMostrar, setQueMostrar] = useState('')

	const hoy = new Date().toISOString().substring(0, 10)

	const refreshSectores = async () => {
		try {
			setLoading(true)
			const { datos } = await api.post('a/ver-areas', {
				cuantos: fetchDeACuantos,
				desde: paginaAct === 1 ? 0 : (paginaAct - 1) * fetchDeACuantos,
				filtros: {
					nombre: [filtroBusqueda]
				}
			})

            console.log('sectores', sectores)

			setSectores(datos.areas)
			setNumResultados(datos.numResultados)
		} catch (error) {
			toast.error('Error de conexión')
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const handleBusqueda = (value) => {
		if (value) {
			setFiltroBusqueda(value)
		} else {
			setFiltroBusqueda('')
		}
	}

	const debouceFiltroBusqueda = React.useCallback(
		debounce(handleBusqueda, 400),
		[]
	)

	useEffect(() => {
		debouceFiltroBusqueda(busqueda)
	}, [busqueda])

	useEffect(() => {
		if (paginaAct === 1) {
			refreshSectores()
		} else {
			setPaginaAct(1)
		}
	}, [filtroBusqueda, fetchDeACuantos])

	useEffect(() => {
		if (!loading) {
			refreshSectores()
		}
	}, [paginaAct])

	const opcionesCuantosMostrar = [10, 25, 50]

	const handleAction = (sector, cual) => {
		setSectorActual(sector, setQueMostrar(cual))
	}

	const guardarDatosNuevo = async (data) => {
        setLoading(true)
		try {
			const { msg } = await api.post('a/areas', data)
			refreshSectores()
			setQueMostrar('')
			toast.success(msg)            
		} catch (error) {
			console.error(error)
			toast.error('Error de conexión')
		} finally {
            setLoading(false)
        }
	}

	const guardarDatosEdicion = async (data) => {
        setLoading(true)
		try {
			const { msg } = await api.put('a/areas/' + data.id, data)
			refreshSectores()
			setQueMostrar('')
			toast.success(msg)

            if (data.files && data.files.length > 0) {
                subirFoto(data.files)
            }
		} catch (error) {
			console.error(error)
			toast.error('Error de conexión')
		} finally {
            setLoading(false)
        }
	}

    const guardar = (data) => {
        if (data.files && data.files.length > 0) {
            subirFoto(data)
        } else {
            delete data.files
            if (data.id) {
                guardarDatosEdicion(data)
            } else {
                guardarDatosNuevo(data)
            }           
        }
    }

	const eliminarSector = async (id) => {
		const { msg } = await api.delete('a/areas/' + id)
		refreshSectores()
		setQueMostrar('')
		toast.success(msg)
	}

    const subirFoto = async (data) => {
        setLoading(true)
        let fotoData = new FormData()
        fotoData.append('foto', data.files[0])
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            //cancelToken: cancelToken.token
        }
        try {
            const { datos: respuesta, msg, error } = await api.post('a/multimedia', fotoData, config)

            if (!error) {
                console.info('Imagen guardado exitosamente', respuesta)
                delete data.files
                data.multimediaId = respuesta.id
                if (data.id) {
                    guardarDatosEdicion(data)
                } else {
                    guardarDatosNuevo(data)
                }
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
	}


	// render
	return (
		<Container fluid id="sectores" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col className="mt-2">
					<Form>
						<Row id="searchbar" className="justify-content-between align-items-center">
							<Col sm={6} md={3} style={{ display: 'block' }}>
								<small>
									<span className="d-none d-sm-inline-block">Mostrar de a</span>
									<Form.Group className="mb-3" style={{ display: 'inline-block' }}>
										<Form.Select size="sm" onChange={(e) => setFetchDeACuantos(e.target.value)} className="mx-1">
											{opcionesCuantosMostrar.map((o) => {
												return (
													<option value={o} key={`mostrar-${o}`}>
														{o}
													</option>
												)
											})}
										</Form.Select>
									</Form.Group>
									<span className="ms-2">sectores</span>
								</small>
							</Col>
							<Col md={5} lg={4}>
								<Form.Group controlId="txtBusqueda">
									<InputGroup>
										<Form.Control
											autoFocus
											value={busqueda}
											onChange={(e) => setBusqueda(e.target.value)}
											type="text"
											placeholder="Buscar"
											aria-label="Buscar"
										/>
										<InputGroup.Text><Form.Label className="mb-0"><i className="fa fa-search"></i></Form.Label></InputGroup.Text>
									</InputGroup>
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
			<Row className="d-flex no-gutters justify-content-between align-items-end mt-2">
				<Col>
					<Button onClick={() => setQueMostrar('nuevo')} variant="primary">
						<i className="fa fa-plus"></i> Nuevo sector
					</Button>
				</Col>
				<Col className="text-end">
					<small>Total resultados: <strong>{numResultados}</strong></small>
				</Col>
			</Row>
			{/* Table */}
			<Row>
				<Col>
					<Table id="tabla-sectores" className="mt-2" bordered hover>
						<thead>
							<tr>
								<th>Id</th>
                                <th style={{width:90}}>Imagen</th>
								<th>Nombre</th>
								<th>Orden</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{loading ? (
								<tr>
									<td colSpan="4">
										<Spinner
											animation="border"
											size="lg"
											role="status"
											aria-hidden="true"
											variant="primary"
											className="my-3"
										/>
									</td>
								</tr>
							) : (
								sectores.length === 0 ? (
									<tr>
										<td colSpan="4">
											<Alert variant="info">No se encontraron sectores</Alert>
										</td>
									</tr>
								) : (
									sectores.map((sector, index) => {
										return (
											<tr key={sector.id}>
												<td>{sector.id}</td>
                                                <td>{(sector.imagenUrl) ? <Thumb size={100} alt="Imagen" url={process.env.REACT_APP_API_URL + sector.imagenUrl}/> : <Thumb url={null}/>}</td>
												<td>{sector.nombre}</td>
												<td>{sector.orden || '-'}</td>
												<td>
													<Button
														onClick={() => handleAction(sector, 'ver')}
														className="me-2"
														variant="primary"
													>
														<i className="fa fa-eye"></i>
													</Button>
													<Button
														onClick={() => handleAction(sector, 'editar')}
														className="me-2"
														variant="secondary"
													>
														<i className="fa fa-pencil"></i>
													</Button>
													<Button
														onClick={() => handleAction(sector, 'eliminar')}
														variant="danger"
													>
														<i className="fa fa-trash"></i>
													</Button>
												</td>
											</tr>
										)
									})
								)
							)}
						</tbody>
					</Table>
				</Col>
			</Row>
			<Row>
				<Col>
					<Paginacion
						pagina={paginaAct}
						numPaginas={Math.ceil(numResultados / fetchDeACuantos) || 1}
						setPagina={setPaginaAct}
					/>
				</Col>
			</Row>
			{/* Panel Nuevo */}
			{queMostrar === 'nuevo' && (
				<NuevoSector
					guardar={guardar}
					setQueMostrar={setQueMostrar}
				/>
			)}
			{/* Panel Ver */}
			{sectorActual && queMostrar === 'ver' &&
				<VerSector
					sector={sectorActual}
					setQueMostrar={setQueMostrar}
				/>
			}
			{/* Panel Editar */}
			{sectorActual && queMostrar === 'editar' &&
				<EditarSector
					sectorOriginal={sectorActual}
					setQueMostrar={setQueMostrar}
					guardar={guardar}
				/>
			}
			{/* Modal Delete */}
			{
				sectorActual &&
				<DeleteSector
					sector={sectorActual}
					mostrar={queMostrar === 'eliminar'}
					setQueMostrar={setQueMostrar}
					eliminarSector={eliminarSector}
				/>
			}
		</Container>
	)
}

export default Sectores