import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

export default function InfoIcon(props) {
	const { text, children } = props

	const renderTooltip = (props) => (
		<Tooltip className="info-tooltip" {...props}>
			{text}
			{children}
		</Tooltip>
	)

	return (
		<OverlayTrigger
			placement="right"
			delay={{ show: 250, hide: 400 }}
			overlay={renderTooltip}
		>
			<i className="fa fa-info-circle"></i>
		</OverlayTrigger>
	)
}
