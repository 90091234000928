import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { FormLabel, Offcanvas } from 'react-bootstrap'
import { PanelFooter, PanelHeader } from 'components/Panel'
import FileUpload from 'components/FileUpload/FileUpload'
import Thumb from 'components/Thumb/Thumb'

function EditarSector(props) {
	const { sectorOriginal, guardar, setQueMostrar } = props

	const [datosSector, setDatosSector] = useState({})

	useEffect(() => {
		setDatosSector({
			id: sectorOriginal.id,
			nombre: sectorOriginal.nombre,
			orden: sectorOriginal.orden,
            files: []
		})

	}, [sectorOriginal])

	const handleClose = () => {
		setQueMostrar('')
		setDatosSector({})
	}

	const handleProcesar = (e) => {
		e.preventDefault()

		const data = {
			...datosSector,
		}
		
		guardar(data)
		handleClose()
	}

	const handleChange = (value, key) => {
		const datosCopia = { ...datosSector }
		datosCopia[key] = value
		setDatosSector(prev => datosCopia)
	}

    const updateFoto = (files) => {
		if (files.length > 0) {
            const datosCopia = { ...datosSector }
            datosCopia.files = files
            setDatosSector(prev => datosCopia)
		} else {
			const datosCopia = { ...datosSector }
            datosCopia.files = []
            setDatosSector(prev => datosCopia)
		}
	}

	// render
	return (
		<Offcanvas show={true} onHide={handleClose} placement="end" scroll="true">
			<PanelHeader onClick={handleClose} iconClass="fa-arrow-left" btnText="Atrás" text="Edición sector" />
			<Offcanvas.Body>
				<h1>Datos del sector</h1>
				{datosSector.id && (
					<Row>
						<Col sm={12} md={6}>
							<Form onSubmit={handleProcesar} className="ms-4">
								<Row>
									<Col>
										<Form.Group className="mb-3" controlId="sector-nombre">
											<FormLabel>Nombre</FormLabel>
											<Form.Control
												value={datosSector.nombre}
												type="text"
												onChange={(e) => handleChange(e.target.value, 'nombre')}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form.Group className="mb-3 col-md-3" controlId="sector-orden">
											<FormLabel>Orden</FormLabel>
											<Form.Control
												value={datosSector.orden}
												type="number"
												onChange={(e) => handleChange(e.target.value, 'orden')}
											/>
										</Form.Group>
									</Col>
								</Row>
                                <Row>
									<Col>
										<Form.Group className="mb-3 col-md-3" controlId="sector-orden">
											<FormLabel>Imagen actual</FormLabel>
											{(sectorOriginal.imagenUrl) ? <Thumb size={200} alt="Imagen" url={process.env.REACT_APP_API_URL + sectorOriginal.imagenUrl}/> : <Thumb size={200} url={null}/>}
										</Form.Group>
									</Col>
								</Row>
                                <Row>
                                    <Col>					
                                        <FileUpload
                                            updateFiles={updateFoto}
                                            title="Seleccionar foto"
                                            accept=".jpg,.jpeg,.png"
                                        />
                                    </Col>
                                </Row>
							</Form>
						</Col>
					</Row>
				)}
			</Offcanvas.Body>
			<PanelFooter>
				<Button variant="outline-tertiary" onClick={handleClose} className="me-2">
					Cancelar
				</Button>
				<Button variant="primary" onClick={handleProcesar}>
					Guardar
				</Button>
			</PanelFooter>
		</Offcanvas>
	)
}

export default EditarSector